import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { setactiveDeactive } from "../../store/feature/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";

const initialValues = {
  Facility: "",
  facility_image1: null,
  facility_image2: null,
  facility_image3: null,
  facility_video: null,
};

const AddFacilities = ({ handleForm, facilityData }) => {
  // console.log("facilityName", facilityData);
  const { id: OmlarId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeDeactive } = useSelector((state) => state.user);

  const validationSchema = Yup.object({
    // Facility: Yup.string().required("Facility name is required"),
    // facility_image1: Yup.mixed().required("Image 1 is required"),
    // facility_image2: Yup.mixed().nullable(),
    // facility_image3: Yup.mixed().nullable(),
    // facility_video: Yup.mixed().nullable(),
  });

  const { values, errors, touched, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, actions) => {
        let data = new FormData();
        data.append("Facility", values.Facility);
        data.append("facility_image1", values.facility_image1);
        if (values.facility_image2)
          data.append("facility_image2", values.facility_image2);
        if (values.facility_image3)
          data.append("facility_image3", values.facility_image3);
        if (values.facility_video)
          data.append("facility_video", values.facility_video);

        try {
          const response = await getAxiosWithToken({
            method: "patch",
            url: `crud/admin-update-facility/${OmlarId}/${facilityData.Id}/`,
            data,
            reqType: "formData",
          });
          if (response.data) {
            alertResponseMsgSuccess("Successeful");
            // alertResponseMsgSuccess(response.data.msg);
            dispatch(setactiveDeactive(!activeDeactive));
            handleForm();
            actions.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          navigate("/");
          alertResponseMsgError(
            error.response?.data?.msg || "An error occurred"
          );
        }
      },
    });

  useEffect(() => {
    if (facilityData) {
      values.Facility = facilityData.Facility;
    }
  }, [facilityData]);

  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">
            Add Facilities
          </h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="Facility" className="form-label">
                Facility Name *
              </label>
              <input
                type="text"
                id="Facility"
                name="Facility"
                placeholder="Enter facility name"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Facility}
                onChange={(e) => setFieldValue("Facility", e.target.value)}
              />
              {errors.Facility && touched.Facility && (
                <InputError>{errors.Facility}</InputError>
              )}
            </div>

            {[
              "facility_image1",
              "facility_image2",
              "facility_image3",
              "facility_video",
            ].map((field, index) => (
              <div className="mb-4" key={field}>
                <label htmlFor={field} className="form-label">
                  {`${
                    field === "facility_video" ? "Video" : `Image ${index + 1}`
                  } ${index === 0 ? "*" : ""}`}
                </label>
                <input
                  type="file"
                  id={field}
                  name={field}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  onChange={(e) => setFieldValue(field, e.target.files[0])}
                />
                {errors[field] && touched[field] && (
                  <InputError>{errors[field]}</InputError>
                )}
              </div>
            ))}

            <div className="flex justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFacilities;
