import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export const DetailHading = ({ logo, name, handleForm }) => {
  return (
    <div className="border-b-2  border-purple-600 pb-2 flex  justify-between items-center">
      <div className="text-3xl font-semibold flex gap-3 items-center ">
        <div className="flex items-center justify-center bg-purple-700 p-2 border-2 border-white shadow-md rounded-full text-white ">
          {logo}
        </div>
        <h1>{name}</h1>
      </div>
      <div>
        {handleForm && (
          <button
            onClick={handleForm}
            type="button"
            className=" bg-textColor hover:opacity-90 active:mx-1 px-2 py-2 rounded-full shadow-md shadow-purple-300 text-white"
          >
            <AddOutlinedIcon />
          </button>
        )}
      </div>
    </div>
  );
};

// -----------------------------------------------------------------
export const HospitalHeading = ({ logo, name, color }) => {
  return (
    <div className="text-xl text-center">
      <div className="flex items-center justify-center" style={{ color }}>
        {logo}
      </div>
      <h1 className="font-extrabold -mt-1" style={{ color }}>
        {name}
      </h1>
    </div>
  );
};
