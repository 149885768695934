import Navbar from "../component/base/Navbar";
import Sidebar from "../component/base/Sidebar";
import Login from "../page/auth/Login";
import HospitalPreview from "../page/hospital/HospitalPreview";
import PolicyPage from "../page/privacyPolicy/PolicyPage";
import { PathRoute, routeConfig } from "./PathRoute";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "../page/NotFound";
import BusinessNotAvailable from "../page/notfoundpage/BusinessNotAvailable";

function AppRoute() {
  // const isLogged = true;
  const { isLogged } = useSelector((state) => state.user);

  return (
    <div className="">
      <Router>
        <Routes>
          {/* Public route accessible without login */}
          <Route
            path={PathRoute.HospitalPreviewLink}
            element={<HospitalPreview />}
          />

          <Route path={PathRoute.PolicyPage} element={<PolicyPage />} />

          {/* Login route for users not logged in */}
          {!isLogged ? (
            <Route path="/" element={<Login />} />
          ) : (
            /* All other routes use the main layout */
            <Route
              path="*"
              element={
                <div className="flex">
                  <aside className=" ">
                    <Sidebar />
                  </aside>

                  {/* Main Content Area */}
                  <div className="flex flex-col min-h-screen w-full">
                    {/* Navbar */}
                    <header className="bg-white">
                      <Navbar />
                    </header>

                    {/* Dashboard */}
                    <main className="p-4 mt-16 md:ml-64 ">
                      {/* Nested Routes for other parts of the app */}
                      <Routes>
                        {routeConfig.map((route, index) => (
                          <Route
                            path={route.path}
                            key={index}
                            element={<route.component />} // Invoke the function here
                          />
                        ))}
                      </Routes>
                    </main>
                  </div>
                </div>
              }
            />
          )}

          <Route path="/404" element={<NotFound />} />
          <Route
            path="/businessnotavailable"
            element={<BusinessNotAvailable />}
          />

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default AppRoute;
