import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { setactiveDeactive } from "../../store/feature/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";


const initialValues = {
  Image: null,
};

const AddImageForm = ({ handleForm, imageId }) => {
  const { id: OmlarId } = useParams();
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);


  const imageUploadSchema = Yup.object({
    Image: Yup.mixed().required("Image is required"),
  });

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: imageUploadSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("Image", values.Image); // Append the image file to FormData

      try {
        const response = await getAxiosWithToken({
          method: "patch",
          url: `crud/admin-update-gallery/${OmlarId}/${imageId}/`,
          data: data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess(response.data.msg);
          dispatch(setactiveDeactive(!activeDeactive))
          handleForm(); // Close form on successful submission
          action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response?.data?.msg || "An error occurred");
      }
    },
  });

  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">Add Image</h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="Image" className="form-label">
                  Upload Image *
                </label>
                <input
                  type="file"
                  id="Image"
                  name="Image"
                  accept="image/*"
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  onChange={(event) => {
                    setFieldValue("Image", event.currentTarget.files[0]);
                  }}
                />
                {errors.Image && touched.Image ? (
                  <InputError error={errors.Image} touched={touched.Image} />
                ) : null}
              </div>

              <div className="flex justify-end mt-6">
                <AddButton type="submit" name="Update" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddImageForm;
