import React from 'react'

const DashChart = ({title, total, icon, levelDown, levelUp, rate}) => {
  return (
    <div className=" rounded-primeryBRedius bg-white hover:-m-1 shadow-md   border-2">
    <div className="flex h-11 w-11 items-center justify-center rounded-full text-blue-400 bg-blue-200 bg-opacity-50 mx-4 my-2">
      {icon}
    </div>

    <div className=" flex items-end justify-between bg-black/5 px-4 py-2 rounded-b-primeryBRedius w-full">
      <div className=''>
        <h4 className="text-4xl font-semibold">
         {total}
        </h4>
        <span className=" text-gray-500 font-medium">{title}</span>
      </div>

      <span
        className={`flex items-center gap-1 text-sm font-medium ${
          levelUp && 'text-green-600'
        } ${levelDown && 'text-meta-5'} `}
      >
        {rate}

        {levelUp && (
          <svg
            className="fill-meta-3"
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
              fill=""
            />
          </svg>
        )}
        {levelDown && (
          <svg
            className="fill-meta-5"
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
              fill=""
            />
          </svg>
        )}
      </span>
    </div>
  </div>
  )
}

export default DashChart
