import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { alertResponseMsgError, alertResponseMsgSuccess } from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setactiveDeactive } from "../../../store/feature/userSlice";

const initialValues = {
  Business_Name: "",
  Banner_Image: "",
  Banner_Image1: "",
  Banner_Image2: "",
  Banner_Image3: "",
  Banner_Image4: "",
  Banner_Image5: "",
  Banner_Image6: "",
  Banner_Image7: "",
  Banner_Image8: "",
};

const BannerImageAdmin = ({ handleForm, businessId, data }) => {
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: async (values, action) => {
        let formData = new FormData();
        formData.append("Business_Name", values.Business_Name);

        // Append banner images to formData
        Object.keys(values).forEach((key) => {
          if (key.startsWith("Banner_Image") && values[key]) {
            formData.append(key, values[key]);
          }
        });

        try {
          const response = await getAxiosWithToken({
            method: "patch",
            url: `Omlar_login_register/admin-update-business/${businessId}/`,
            data: formData,
            reqType: "formData",
          });
          if (response) {
            alertResponseMsgSuccess("Successfully updated");
            handleForm();
            action.resetForm();
            dispatch(setactiveDeactive(!activeDeactive));
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(error.response?.data?.msg || "An error occurred");
        }
      },
    });

  useEffect(() => {
    if (data) {
      Object.keys(initialValues).forEach((key) => {
        if (data[key]) {
          setFieldValue(key, data[key]);
        }
      });
    }
  }, [data]);

  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full top-32 my-6 mx-auto max-w-3xl">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">Business Details</h2>

          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-2">
            <div className="mb-4">
              <label htmlFor="Business_Name" className="form-label">
                Business Name *
              </label>
              <input
                type="text"
                id="Business_Name"
                name="Business_Name"
                placeholder="Enter business name"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Business_Name}
                onChange={handleChange}
              />
              <InputError error={errors.Business_Name} touched={touched.Business_Name} />
            </div>

            {/* Add banner image fields */}
            {Array.from({ length: 9 }).map((_, index) => (
              <div className="mb-4" key={index}>
                <label htmlFor={`Banner_Image${index}`} className="form-label">
                  Banner Image {index + 1} *
                </label>
                <input
                  type="file"
                  id={`Banner_Image${index}`}
                  name={`Banner_Image${index}`}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  onChange={(event) =>
                    setFieldValue(`Banner_Image${index}`, event.currentTarget.files[0])
                  }
                />
                <InputError
                  error={errors[`Banner_Image${index}`]}
                  touched={touched[`Banner_Image${index}`]}
                />
              </div>
            ))}

            {/* Other form fields */}
            {/* ...additional fields like Email, Address, etc... */}

            <div className="flex gap-4 justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BannerImageAdmin;
