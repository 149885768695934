import React, { useEffect, useState } from 'react'
import Dynamictable from '../../../component/table/Dynamictable'
// import {  useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import { getAxiosWithToken } from '../../../axios/AxiosObj';
// import { PathRoute } from '../../router/PathRoute';
// import { logoutUser } from "../../store/feature/userSlice";
// import DeleteIcon from '@mui/icons-material/Delete';


const FeedbackTable = ({allfeedback}) => {
  
  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    if (allfeedback?.length > 0) {
      const modifiedData = allfeedback?.map((option) => {
        return {
          item1: option.Feedback?.slice(0,10) || "NA",
          item2: option.Mobile_No || "NA",
          item3: option.Email_Id || "NA",
          feedback: "Feedback",
          // delete: <DeleteIcon/>,
          feedbackdeail: option.Id,
          uniqueKey: option.Id,
        };
      });

      setTableData(modifiedData);
    }
  }, [allfeedback]);
      const tableHeadings = ["S.No."," Note", "Contact", " Email", "Details",];
  return (
    <div>
      <div className="">
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
    </div>
  )
}

export default FeedbackTable;
