import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import { useNavigate, useParams } from "react-router";
import { useEffect,useState,useRef } from "react";
import InputError from "../../component/props/InputError";
import { useDispatch, useSelector } from "react-redux";
import { setactiveDeactive } from "../../store/feature/userSlice";


const initialValues = {
  Our_Blog: "",
  blogImage1: null,
  blogImage2: null,
  blogImage3: null,
  blogVideo: null,
};

const AddBlog = ({ handleForm, detailsText }) => {
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  const fileInputRef = useRef(null);
  const [docs, setDocs] = useState(null);

  const validationSchema = Yup.object({
    Our_Blog: Yup.string().required("Blog text is required"),
  });

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const data = new FormData();
      data.append("Our_Blog", values.Our_Blog);

      // Append files if selected
      // if (values.blogImage2) data.append("blogImage2", values.blogImage2);

      if (docs) data.append("Blog_image1", docs);
      if (values.blogImage2) data.append("Blog_image2", values.blogImage2);
      if (values.blogImage3) data.append("Blog_image3", values.blogImage3);
      if (values.blogVideo) data.append("Blog_video", values.blogVideo);

      try {
        const response = await getAxiosWithToken({
          method: "patch",
          url: `crud/admin-update-blog/${OmlarId}/${detailsText.Id}/`,
          data,
          reqType: "formData",
        });

        if (response.data) {
          alertResponseMsgSuccess("Blog updated successfully!");
          handleForm();
          actions.resetForm();
          dispatch(setactiveDeactive(!activeDeactive));

        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        alertResponseMsgError(
          error.response?.data?.msg || "An error occurred"
        );
        navigate("/");
      }
    },
  });

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (files && files[0]) {
      setFieldValue(name, files[0]);
    }
  };
  //----------------------Doucments ---------------------------------------


  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      // Validate file size (limit to 10 MB)
      if (file.size > 10000000) {
        alertResponseMsgError("File size should be 10 MB or less");
        return false;
      }

      // Validate file type (allow only PDF, DOCX, JPG, JPEG, PNG)
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be PDF, DOCX, JPG, JPEG, or PNG"
        );
        return false;
      }

      // If the file is valid, update the state
      setDocs(file);
      setFieldValue("upload_document", file); // Update Formik state

      // If you want to preview image files (optional)
      // if (file.type.startsWith("image/")) {
      //   const imageUrl = URL.createObjectURL(file);
      //   // Uncomment the line below to use for image preview
      //   // setProfilePicPerview(imageUrl);
      // }
    }
  };

  useEffect(() => {
    if (detailsText) {
      setFieldValue("Our_Blog", detailsText.Our_Blog);
    }
  }, [detailsText, setFieldValue]);

  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">Blog</h2>

          <form onSubmit={handleSubmit}>
            {/* Blog Text Field */}
            <div className="mb-4">
              <label htmlFor="Our_Blog" className="form-label">
                Blog *
              </label>
              <textarea
                id="Our_Blog"
                name="Our_Blog"
                placeholder="Enter blog text"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={(e) => setFieldValue("Our_Blog", e.target.value)}
                value={values.Our_Blog}
              />
              {errors.Our_Blog && touched.Our_Blog && (
                <InputError errorMessage={errors.Our_Blog} />
              )}
            </div>

            {/* Blog Image 1 */}
            <div className="mb-4">
              <label htmlFor="blogImage1" className="form-label">
                Blog Image 1 *
              </label>
              <input
                type="file"
                id="blogImage1"
                name="blogImage1"
                accept="image/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                ref={fileInputRef}
                onChange={handleChangeFile}
              />
            </div>

            {/* Blog Image 2 */}
            <div className="mb-4">
              <label htmlFor="blogImage2" className="form-label">
                Blog Image 2
              </label>
              <input
                type="file"
                id="blogImage2"
                name="blogImage2"
                accept="image/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={handleFileChange}
              />
            </div>

            {/* Blog Image 3 */}
            <div className="mb-4">
              <label htmlFor="blogImage3" className="form-label">
                Blog Image 3
              </label>
              <input
                type="file"
                id="blogImage3"
                name="blogImage3"
                accept="image/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={handleFileChange}
              />
            </div>

            {/* Blog Video */}
            <div className="mb-4">
              <label htmlFor="blogVideo" className="form-label">
                Blog Video
              </label>
              <input
                type="file"
                id="blogVideo"
                name="blogVideo"
                accept="video/*"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={handleFileChange}
              />
            </div>

            <div className="flex justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
