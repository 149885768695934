// import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getAxiosWithToken } from '../../axios/AxiosObj';
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from '../../function/utilies';
import { useDispatch, useSelector } from 'react-redux';
import { setactiveDeactive } from "../../store/feature/userSlice";
import { useNavigate } from 'react-router';


export default function DeleteDialogBox({
  open,
  setOpen,
  data,
  redirect =false,
}) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeDeactive } = useSelector((state) => state.user);
  const handleClose = () => setOpen(false);

  const handleDeleteApi = async () => {
    try {
      // Check if data and deleteUrl exist
      if (data && data.deleteUrl) {
        // Make the API call using Axios with the delete method
        const response = await getAxiosWithToken({
          method: 'delete',
          url: data.deleteUrl,
        });
  
        // Check if the response contains a success message
        if (response) {
          alertResponseMsgSuccess("successful"); // Success alert
          setOpen(false); // Close the modal or any other component
          dispatch(setactiveDeactive(!activeDeactive))
        } else {
          navigate("/");
          // If no detail message in response, show a generic error
          alertResponseMsgError(response.data?.detail || 'An error occurred');
        }
      } else {
        // Handle missing data or deleteUrl
        console.error("Delete URL is not available in data");
        alertResponseMsgError('Delete URL is not available');
      }
    } catch (error) {
      navigate("/");
      // Handle any errors that occurred during the API call
      console.error("Error occurred during API call:", error);
  
      // Provide a user-friendly error message
      alertResponseMsgError(error?.response?.data?.detail || 'Failed to delete. Please try again.');
    }
  };
  

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{data?.headerText || 'Confirmation'}</DialogTitle>

      <DialogContent >

        <p className='text-xl'>{`Are you sure you want to delete this ${data?.paraText}?` || 'Are you sure you want to delete this item?'}</p>
        {/* <p className='text-red-500'>{`This action can not be undone.`}</p> */}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteApi} color="primary">
          Confirm
        </Button>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

//-----------------------------------------------------------------------------------------
