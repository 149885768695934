import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
// import { useNavigate } from "react-router";
import InputError from "../../../component/props/InputError";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";
import { useDispatch, useSelector } from "react-redux";
import { setactiveDeactive } from "../../../store/feature/userSlice";
const initialValues = {
  Expertise_Name: "",
};
const UpdateExpertiesAdmin = ({ handleForm, UpdateId }) => {
  const { activeDeactive } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { id: OmlarId } = useParams();

  const navigate = useNavigate();
  const supplierCreateSchema = Yup.object({
    Expertise_Name: Yup.string().required("Partner Name is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit,setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: supplierCreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("Expertise_Name", values.Expertise_Name);

      try {
        const response = await getAxiosWithToken({
          method: "patch",
          url: `crud/admin-update-expertise/${OmlarId}/${UpdateId.Id}/`,
          data: data,
        });
        console.log("response.data.success", response.data);
        if (response.data) {
          alertResponseMsgSuccess("Successeful");
            // alertResponseMsgSuccess(response.data.msg);
            dispatch(setactiveDeactive(!activeDeactive));
          handleForm(); // Close form on successful submission
          action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        navigate("/")
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("An error occurred");
        }
      }
    },
  });
  //------------------------getPatner----------------------------------------------
  useEffect(() => {
    if (UpdateId) {
      setFieldValue("Expertise_Name", UpdateId.Expertise_Name);
    }
  }, [UpdateId, setFieldValue]);
  

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-expertise/${OmlarId}/${UpdateId.Id}/`,
        headerText: `Confirm Deletion`,
        paraText: `Partner?`,
      });
    }
  };

  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon/>
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">
              Update Experties
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="Expertise_Name" className="form-label">
                   Experties *
                </label>
                <input
                  type="text"
                  id="Expertise_Name"
                  name="Expertise_Name"
                  placeholder="Enter name"
                  className="popform-input"
                  value={values.Expertise_Name}
                  onChange={handleChange}
                />
                <InputError
                  error={errors.Expertise_Name}
                  touched={touched.Expertise_Name}
                  warning={false}
                />
              </div>

              <div
                // onClick={handleSubmit}
                className="flex gap-4 justify-end mt-6"
              >
                <AddButton type="submit" name="Update" />
                <div
                  className="w-full"
                  onClick={() => handleDeleteModel(values.Id)}
                >
                  <AddButton name="Delete" />
                </div>
              </div>
            </form>
          </div>
        </div>
        <DeleteDialogBox open={open} setOpen={setOpen} data={deleteModelData} />
      </div>
    </>
  );
};

export default UpdateExpertiesAdmin;
