import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAxiosWithOutToken } from "../../../axios/AxiosObj";
import { useNavigate } from "react-router";

const AdsForWeb = () => {
  const navigate = useNavigate();
  const { DynamicColor } = useSelector((state) => state.user);
  const [addShow, setAddShow] = useState(true);
  const [sound, setSound] = useState(true);
  const [getAds, setGetAds] = useState([]);

  const videoRef = useRef(null);

  const hideAds = () => {
    setAddShow(!addShow);
  };

  const adsSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !sound; // Mute if sound is false, unmute if sound is true
      setSound(!sound); // Toggle sound state
    }
  };

  //------------------------------------get ads details-----------------------------------
  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const response = await getAxiosWithOutToken({
          method: "get",
          url: `crud/Get_All_adv_Image_Video/`,
        });

        if (response.data) {
          setGetAds(response.data);
        } else {
          navigate("/404");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          navigate("/");
        } else {
          navigate("/404");
        }
      }
    };

    fetchAdsData();
  }, []);

  return (
    <>
      <div
        className={`p-5 ${
          addShow ? "mb-44" : " "
        }  flex items-center justify-center my-5`}
      ></div>
      {addShow && (
        <div
          className={`fixed bottom-6 right-0 z-40 w-full ${
            addShow ? "" : "hidden "
          }`}
          style={{ background: DynamicColor }}
        >
          <div
            onClick={hideAds}
            className="absolute z-30 right-0 px-2 hover:cursor-pointer"
          >
            <p className="text-sm font-bold text-white">SKIP AD</p>
          </div>

          {getAds &&
            getAds?.map((item, index) =>
              item.Type === "video" ? (
                <div key={index}>
                  <div
                    onClick={adsSound}
                    className="absolute z-30 text-white px-2 hover:cursor-pointer"
                  >
                    <p>{sound ? <VolumeOffIcon /> : <VolumeUpIcon />}</p>
                  </div>
                  <video
                    ref={videoRef}
                    src={item.Adv_Image_video}
                    autoPlay
                    loop
                    muted
                    className="w-full h-52 object-cover"
                  />
                </div>
              ) : (
                <img
                  key={index}
                  src={item.Adv_Image_video}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-52 object-cover "
                />
              )
            )}
        </div>
      )}
    </>
  );
};

export default AdsForWeb;
