import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import secureLocalStorage from "react-secure-storage";

const initSidebarHandle = false;
// const initUrl = 'https://api.omlarmulti.com';
const initUrl = '';

const initDynamicColor = '#a64686';


// Retrieve and parse initial values from secureLocalStorage
const initialAccessToken = secureLocalStorage.getItem("access") || null;
const initialIsLoggedIn =
  secureLocalStorage.getItem("isLIn") === true ? true : false;
  const initialRole = secureLocalStorage.getItem("role") || null;

export const userLogin = createAsyncThunk(
  "user/userLogin",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("login_credential", username);
      formData.append("password", password);
      const response = await getAxiosWithOutToken({
        method: "POST",
        url: "Omlar_login_register/otp-login/",
        data: formData,
      });

      // console.log("response", response);
      return response.data; // This returns the response payload to the reducer
    } catch (error) {
      const errorMessage = error?.response?.data?.msg || "Invalid credentials";
      alertResponseMsgError(errorMessage);
      return rejectWithValue(errorMessage); // Pass the error message to the reducer
    }
  }
);

export const userLogOut = createAsyncThunk("user/userLogOut", async () => {
  try {
    // Clear local storage and reload the page on logout
    localStorage.clear();
    secureLocalStorage.clear();
    window.location.reload();
  } catch (error) {
    // Handle error or dispatch error-related actions here
    console.log(error);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    sidebarHandle: initSidebarHandle,
    activeDeactive: false,
    businessUpdate: false,

    isLogged: initialIsLoggedIn,
    role: initialRole,
    accesstoken: initialAccessToken,
    loading: false, // Handle loading state during async operations
    error: null, // To store any errors during login/logout
    UrlLink: initUrl,
    DynamicColor:initDynamicColor,
    
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accesstoken = action.payload;
      secureLocalStorage.setItem("access", action.payload); // Store in secure storage
    },
    setIsLoggedIn: (state, action) => {
      state.isLogged = action.payload;
      secureLocalStorage.setItem("isLIn", action.payload.toString()); // Store as string in secure storage
    },
    logoutUser: (state) => {
      state.isLogged = false;
      state.role = null;
      state.accesstoken = null;
      secureLocalStorage.clear();
    },
    setSidebarHandle: (state, action) => {
      state.sidebarHandle = action.payload;
    },
    setactiveDeactive: (state, action) => {
      state.activeDeactive = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setDynamicColor: (state, action) => {
      state.DynamicColor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        // state.error = null; // Clear any previous error
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const { role, access_token, message } = action.payload;
        if (access_token) {
          state.loading = false;
          state.isLogged = true;
          state.role = role;
          state.accesstoken = access_token;
          // Save data to secure storage
          secureLocalStorage.setItem("isLIn", true);
          secureLocalStorage.setItem("access", access_token);
          // secureLocalStorage.setItem("refresh", refresh_token);
          // secureLocalStorage.setItem("role", role);

          // Display login success message
          alertResponseMsgSuccess(message || "Login Successful!");
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Login failed. Please try again."; // Handle login errors
      });
  },
});

export const {
  setAccessToken,
  setactiveDeactive,
  setIsLoggedIn,
  logoutUser,
  setPage,
  setSidebarHandle,
  setDynamicColor,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
