import React, { useState } from "react";
import { useNavigate } from "react-router";
import ActiveButton from "./ActiveButton";
import AddEvent from "../../page/event/AddEvent";
import DeleteDialogBox from "../cards/DeleteDialogBox";
import AppointmnetDetail from "../../page/bussiness/doctor/AppointmnetDetail";
import FeebbackDetails from "../../page/bussiness/feedback/FeebbackDetails";
import CardDesignDetails from "../../page/wishes/CardDesignDetails";
import StatusButton from "../../page/wishes/StatusButton";

const Dynamictable = ({ tableHead, tableData, loading }) => {
  const navigate = useNavigate();
  const openDetail = (id, Idlink) => {
    console.log("it is working ");

    if (id && Idlink) {
      const links = Idlink?.replace(":id", id);
      console.log("links", links);
      navigate(`${links}`);
    }
  };

  const handleButtonClick = (e, uniqueKey, businessLink) => {
    e.stopPropagation(); // Prevents the row click event from being triggered
    const links = businessLink.replace(":id", uniqueKey);
    navigate(`${links}`);
  };

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    // console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/events/delete/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `event`,
      });
    }
  };
  return (
    <div className="w-full">
      <div className=" overflow-x-auto shadow-md my-4 border-4   rounded-primeryBRedius">
        <table className="w-full min-w-[600px]  text-left  text-textColor rounded-primeryBRedius">
          <thead className=" text-white uppercase bg-gray-600  ">
            <tr>
              {Object.entries(tableHead).map(([key, value]) => (
                <th scope="col" key={key} className="px-6 py-4">
                  {value}
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <tbody></tbody>
          ) : (
            <tbody className="">
              {tableData?.map((data, index) => (
                <tr
                  onClick={() => openDetail(data.uniqueKey, data.link)}
                  key={index}
                  className="bg-white hover:bg-slate-100 border-b-2 border-[#eee] cursor-pointer "
                >
                  <td className="px-6 py-4">{index + 1}.</td>
                  {data.item1 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item1}
                    </td>
                  ) : null}
                  {data.item2 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item2}
                    </td>
                  ) : null}
                  {data.item3 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item3}
                    </td>
                  ) : null}
                  {data.item4 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item4}
                    </td>
                  ) : null}
                  {data.item5 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item5}
                    </td>
                  ) : null}
                  {data.item6 ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      {data.item6}
                    </td>
                  ) : null}
                  {data.cardDesign ? (
                    <td className="px-6 py-2 whitespace-nowrap">
                      <StatusButton
                        isActive={data.status}
                        uniqueKey={data.uniqueKey}
                        url={`crud/admin/design-cards/${data.uniqueKey}/update-status/`}
                      />
                    </td>
                  ) : null}
                  {data.profession ? (
                    <td className="px-6 py-2">{data.profession}</td>
                  ) : null}
                  {data.is_active !== undefined && (
                    <td className="px-6 py-2">
                      <button
                        onClick={(e) =>
                          handleButtonClick(
                            e,
                            data.BusinessId,
                            data.businessLink
                          )
                        }
                        className={`px-2 `}
                      >
                        {data.eyeBtn}
                      </button>
                    </td>
                  )}
                  {data.is_active !== undefined && (
                    <td className="px-6 py-2">
                      <ActiveButton
                        isActive={data.is_active}
                        uniqueKey={data.uniqueKey}
                      />
                    </td>
                  )}
                  {data.event !== undefined && (
                    <td className="px-6 py-2">
                      <AddEvent
                        isActive={data.is_active}
                        uniqueKey={data.uniqueKey}
                        eventdeail={data.eventdeail}
                        // businessId ={data.id}
                      />
                    </td>
                  )}
                  {data.cardDesign !== undefined && (
                    <td className="px-6 py-2">
                      <CardDesignDetails
                        uniqueKey={data.uniqueKey}
                        // businessId ={data.id}
                      />
                    </td>
                  )}
                  {data.feedback !== undefined && (
                    <td className="px-6 py-2">
                      <FeebbackDetails
                        isActive={data.is_active}
                        uniqueKey={data.uniqueKey}
                      />
                    </td>
                  )}
                  {data.Appointment !== undefined && (
                    <td className="px-6 py-2">
                      <AppointmnetDetail
                        isActive={data.is_active}
                        uniqueKey={data.uniqueKey}
                        eventdeail={data.Payment_receipt}
                        // businessId ={data.id}
                      />
                    </td>
                  )}
                  {data.whatsappToken !== undefined && (
                    <td className="px-6 py-2">
                      <AddEvent
                        isActive={data.is_active}
                        uniqueKey={data.uniqueKey}
                        eventdeail={data.eventdeail}
                        // businessId ={data.id}
                      />
                    </td>
                  )}
                  {data.delete !== undefined && (
                    <td className="px-6 py-2">
                      <div onClick={() => handleDeleteModel(data.eventdeail)}>
                        {data.delete}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <DeleteDialogBox
          open={open}
          setOpen={setOpen}
          data={deleteModelData}
          // redirect="/tenant/person"
        />
      </div>
    </div>
  );
};

export default Dynamictable;
