import { useState } from "react";
import UpdateExpertiesAdmin from "./UpdateExpertiesAdmin";

const ExpertiseAdmin = ({ expertise }) => {
  //----------------------------------------------------------------------
  const [UpdateId, setUpdateId] = useState("");

  const [emplaneleUpdateShow, srtEmplanelUpdateShow] = useState(false);

  const handleEmplaneUpdateForm = (Id) => {
    srtEmplanelUpdateShow(!emplaneleUpdateShow);
    setUpdateId(Id);
  };
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4 ">
        <ul className="pl-5">
          {expertise &&
            expertise.map((exprt, index) => (
              <p
                onClick={() => handleEmplaneUpdateForm(exprt)}
                key={index}
                className=" text-lg  font-semibold cursor-pointer"
              >
                &#9679; {exprt.Expertise_Name}
              </p>
            ))}
        </ul>
      </div>
      {emplaneleUpdateShow && (
        <UpdateExpertiesAdmin
          UpdateId={UpdateId}
          handleForm={handleEmplaneUpdateForm}
        />
      )}
    </div>
  );
};

export default ExpertiseAdmin;
