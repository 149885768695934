import React, { useEffect, useState } from "react";
// import { DoctorFeeCard } from "../../component/cards/DoctorCard";
// import BasicDetails from "./BasicDetails";
// import DateTimeForm from "./DateTimeForm";
// import AppointmentSummary from "./AppointmentSummary";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser } from "../../../store/feature/userSlice";
import { PathRoute } from "../../../router/PathRoute";
import Dynamictable from "../../../component/table/Dynamictable";



const DoctorAppointmentTable = ({showAppointmentform,drId}) => {
  const tableHeadings = ["S.No", "Name","contact", "age","Date", "Time","Payment" ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);

  console.log("allDataRaw",allDataRaw)
  const getAllData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `Other_crud/booking-summary/by-doctor/${drId}/`,
      });

      if (response ) {
        setAllDataRaw(response.data.results);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch(logoutUser());
        navigate("/");
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    if (isLogged && drId) {
      getAllData();
    }
  }, [isLogged,drId]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.First_Name +" "+ option.Last_Name,
          item2: option.Phone_No,
          item3: option.Age,
          item4: option.Date,
          item5: option.Time,
          Appointment: option.Id,
          Payment_receipt: option.Payment_receipt,
          uniqueKey: option.Id,
          // link: PathRoute.CategoryDetailLink
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);
  return (
    <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/30 backdrop-blur-sm">
      {/* Modal Container */}
      <div className="relative p-4 md:px-12  w-full my-6 mx-auto">
        <div
          onClick={showAppointmentform}
          className="text-right  bg-white  cursor-pointer"
        >
          <CloseOutlinedIcon />
        </div>
        {/* Modal Content */}
        <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <Dynamictable tableData={tableData} tableHead={tableHeadings} />

         
        </div>
      </div>
    </div>
  );
};

export default DoctorAppointmentTable;
