import React from "react";

const AddBusiness = () => {
  return (
    <div className="p-4">
      <div className="rounded-sm border border-stroke bg-white shadow p-4">
        {/* Business Name */}
        <div className="mb-6">
          {/* Label */}
          <label htmlFor="businessName" className="form-label">
            Business Name
          </label>

          {/* Input */}
          <input
            type="text"
            id="businessName"
            name="businessName"
            placeholder="Enter business name"
            className="form-input"
          />
        </div>

        {/* Contact */}
        <div className="mb-6">
          <label className="mb-3 block text-black dark:text-white">
            Contact
          </label>
          <input
            type="text"
            placeholder="Enter contact number"
            className="w-full rounded-lg border border-stroke bg-transparent py-2 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        {/* Email */}
        <div className="mb-6">
          <label className="mb-3 block text-black dark:text-white">Email</label>
          <input
            type="email"
            placeholder="Enter email address"
            className="w-full rounded-lg border border-stroke bg-transparent py-2 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>

        {/* Owner */}
        <div className="mb-6">
          <label className="mb-3 block text-black dark:text-white">Owner</label>
          <input
            type="text"
            placeholder="Enter owner's name"
            className="w-full rounded-lg border border-stroke bg-transparent py-2 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
        </div>
      </div>
    </div>
  );
};

export default AddBusiness;
