import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";
import EditServiceAdmin from "./EditServiceAdmin";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ServicesAdmin = ({
  handlePharmacyServicesForm,
  img,
  name,
  EnqNo,
  tag,
  allData,
  Info,
}) => {
  const { id: OmlarId } = useParams();
  const { UrlLink } = useSelector((state) => state.user);
  const [docterFromShow, srtDocterFromShow] = useState(false);

  const handledoctoerForm = () => {
    srtDocterFromShow(!docterFromShow);
  };
  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-farmacy/${OmlarId}/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `service?`,
      });
    }
  };
  return (
    <div className=" gap-4 items-center text-center w-full my-2  bg-white rounded-primeryBRedius">
      <div className=" text-center rounded-t-primeryBRedius py-3  bg-appColor">
        <h1 className="text-xl font-bold px-2 text-white">{tag}</h1>
      </div>
      <div className="flex justify-around items-center gap-4 p-2">
        <div className="text-left">
          <h3 className={`text-3xl font-bold text-appColor`}>{name}</h3>
          <h3 className={` font-bold`}>Enquiry No.: {EnqNo}</h3>
          <div className="flex gap-2">
          <div
            onClick={() => handledoctoerForm()}
            className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
          >
            <EditIcon />
          </div>
          <div
            onClick={() => handleDeleteModel(allData.Id)}
            className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
          >
            <DeleteIcon />
          </div>
        </div>
        </div>
        <div className="flex justify-center my-2">
          <img src={UrlLink + img} alt="img" className="w-full h-44 rounded-primeryBRedius border my-2" />
        </div>
      </div>

      {/* <div
        className=" text-center rounded-xl mx-8 my-2"
        style={{ background: DynamicColor }}
      >
        <h1 className="text-xl font-extrabold text-white">{tag}</h1>
      </div> */}
      <div className="flex gap-2 justify-center ">
      
      </div>
      {docterFromShow && (
        <EditServiceAdmin
          handleForm={handledoctoerForm}
          allData={allData}
          Info={Info}
        />
      )}
      <DeleteDialogBox open={open} setOpen={setOpen} data={deleteModelData} />
      {/* <div className="flex justify-center">
        <div
          onClick={handlePharmacyServicesForm}
          className="text-center text-sm flex gap-2"
        >
          <p>Click Here</p>
          <p
            className=" rounded-full flex  items-center px-1 text-white  max-w-min "
            style={{ background: DynamicColor }}
          >
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "15px" }} />
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ServicesAdmin;
