import React from "react";
import { Link } from "react-router-dom";

const Sidebartag = ({ logo, isActive, name, path }) => {
  console.log("isActive", isActive);
  return (
    <Link
      to={path}
      className={`flex items-center gap-2 mr-4 px-4 py-2 my-2 transition  cursor-pointer text-gray-600  hover:shadow-inner hover:shadow-slate-400  hover:font-semibold hover:bg-white hover:text-textColor rounded-r-primeryBRedius  translate-x-0 duration-100 ease-out ${
        isActive
          ? "font-semibold bg-purple-700  text-white shadow-md "
          : ""
      }`}
    >
      {logo}
      <h1 className="">{name}</h1>
    </Link>
  );
};
export default Sidebartag;
