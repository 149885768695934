import React, { useEffect, useState } from 'react'
import Dynamictable from '../../component/table/Dynamictable'
import {  useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAxiosWithToken } from '../../axios/AxiosObj';
// import { PathRoute } from '../../router/PathRoute';
// import { logoutUser } from "../../store/feature/userSlice";
import DeleteIcon from '@mui/icons-material/Delete';


const EventTable = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  // console.log("param OmlarId", OmlarId);
  const { isLogged,activeDeactive } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("allDataRaw",tableData);
  // console.log("loading",loading);

  const getBusinessDetail = async () => {
    try {
      setLoading(true); // Start loading
      const response = await getAxiosWithToken({
        method: "get",
        url: `crud/events/business/${OmlarId}/`,
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        // await dispatch(logoutUser());
      } else {
        // navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isLogged) {
      getBusinessDetail();
    }
  }, [isLogged,activeDeactive]);

  useEffect(() => {
    if (allDataRaw.length > 0) {
      const modifiedData = allDataRaw.map((option) => {
        return {
          item1: option.Title || "NA",
          item2: option.date || "NA",
          item3: option.details.slice(0,15) || "NA",
          event: "event",
          delete: <DeleteIcon/>,
          eventdeail: option.Id,
          uniqueKey: option.Id,
        };
      });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);
  
 
      
      const tableHeadings = ["S.No."," Title", "Date", " Details", "Edit","Delete"];
  return (
    <div>
      <div className="">
        {loading && <div>Loading...</div>}
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
    </div>
  )
}

export default EventTable;
