import React from "react";
import OmlarLogo from "../../images/OmlarLogo.png";

const PolicyPage = () => {
  return (
    <div className="bg-webBgColor">
      <header
        className="text-white sm:py-4 sm:px-6 px-2 py-2"
        style={{ backgroundColor: "#a64686" }}
      >
        <div className="flex items-center justify-between ">
          <div className="flex gap-2 items-start justify-center px-2">
            <img
              src={OmlarLogo}
              alt="Logo"
              className="w-7 ring-1 ring-white rounded-full"
            />
            <div className="text-right">
              <h1 className="md:text-3xl text-2xl font-extrabold text-white">
                OMLAR
              </h1>
              <p className="text-[9px] mt-[-5px]">Stay in touch...</p>
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <h1 className=" font-bold font-poppins text-white leading-tight">
              Create New <br /> Business ID
            </h1>
          </div>
        </div>
      </header>
      <div className="mt-4">
        <h1 className="text-center text-[#a64686] text-2xl font-bold">
          Privacy Policy
        </h1>
      </div>
      <div className="p-5 text-sm ">
        <p className="text-justify mb-4">
          OMLAR MULTI has created this privacy statement in order to demonstrate
          our organization's commitment to privacy. The following discloses our
          information gathering and dissemination practices. Our privacy policy
          is prepared just to make sure to everyone that we care about your
          personal details. We are suggesting to everyone to read our privacy
          policy before navigating our website thoroughly. By accessing OMLAR
          MULTI websites you agree to the terms of the OMLAR MULTI Privacy
          Policy outlined below. If you do not agree to the terms of this
          policy, please do not access or use any of the OMLAR MULTI sites.
        </p>
        <p className="text-justify  mb-4 font-bold ">Data Collection</p>
        <p className="text-justify  mb-4">
          OMLAR MULTI websites use cookies to collect information in order to
          provide better services to our users and improve our marketing
          campaigns. We collect information in the following ways:
        </p>
        <p className="text-justify  mb-4">
          Data that you affirmatively choose to submit to us (such as your name,
          email address, company name, title, country, phone number) for our
          marketing purposes such as website analytics, visitor trends,
          engagement with our website content, etc;
        </p>
        <p className="text-justify  mb-4">
          Information we get from your use of our websites such as IP address,
          browser type, ISP, referring/exit pages, platform type, date/time
          stamp, number of clicks, domain name and country/state of all visitors
          to the site; and location information: when you use OMLAR MULTI
          websites, we may collect and process information about your actual
          geolocation. We use various technologies and tools to determine it.
        </p>
        <p className="text-justify  mb-4">
          In order access some highly secured pages, we need to extract user's
          personal details and IP address but it doesn't mean that we will share
          or exchange your details with any third party without your permission.
          Your personal details will remain secured within the OMLAR MULTI.
        </p>
        <p className="text-justify  mb-4">
          We stores users personal details on our database for specific time
          period and we have rights to delete that information from our database
          as per our requirement. So user can opt to stay for long by updating
          their details frequently or can remove their link from the website as
          soon as they are ready with the website or don't take up the services
          any longer.
        </p>
        <p className="text-justify  mb-4">
          Any contact details that are provided by the visitors themselves
          through the enquiry form, are solely used for the purpose of
          responding to visitor's enquiries alone; OMLAR MULTI shall not
          share/sell visitor's contact information with/to any third party.
        </p>
        <p className="text-justify  mb-4 font-bold">Site Usage</p>
        <p className="text-justify  mb-4">
          No visitor of this web site may distribute exchange, modify, sell, or
          transmit any information published on this site, including but not
          limited to any text, images, audio or video, for any business or
          commercial purpose. The site cannot be used for or involved in illegal
          activity of any kind.
        </p>
        <p className="text-justify  mb-4 font-bold">Personal Information Disclosure</p>
        <p className="text-justify  mb-4">
          OMLAR MULTI may disclose your personal information when it is required
          by law or there are sufficient grounds to believe that this action is
          necessary to:
        </p>
        <p className="text-justify  mb-4">
          Investigate unlawful activities or comply with legal process served on
          OMLAR MULTI Identify persons violating the law, OMLAR MULTI Privacy
          Policy or the rights of third parties Reply to user requests
        </p>
        <p className="text-justify  mb-4 font-bold">How We Use This Information</p>
        <p className="text-justify  mb-4">
          We use personal information in the following ways:
        </p>
        <p className="text-justify  mb-4">
          To provide you with information about new services, newsletters,
          informative emails, and marketing purposes.
        </p>
        <p className="text-justify  mb-4">
          To assist us in creating content that is relevant to you. To provide
          you with special OMLAR MULTI specific offer that may be of interest to
          you.
        </p>
        <p className="text-justify  mb-4 font-bold">Non-Confidential Information</p>
        <p className="text-justify  mb-4">
          The information you submit in a blog discussion or comment is public
          information and will not be treated as confidential. Such information
          can be collected and used by others.
        </p>
        <p className="text-justify  mb-4 font-bold">Privacy Policy Updates</p>
        <p className="text-justify  mb-4">
          We deserve the right to change our privacy policy either partly or
          full privacy as per the company requirement, so you may frequently
          visit our section to remain up-to-date about the
          privacy policy changes.{" "}
        </p>
      </div>
    </div>
  );
};

export default PolicyPage;
