import React, { useEffect, useState } from "react";
// import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Person4Icon from "@mui/icons-material/Person4";
import Sidebartag from "../props/Sidebartag";
import { PathRoute } from "../../router/PathRoute";
import OmlarLogo from "../../images/OmlarLogo.png";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarHandle } from "../../store/feature/userSlice";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
// import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import AddBackgroundImage from "../../page/bussiness/backgroundImage/AddBackgroundImage";
import AddAds from "../../page/bussiness/ads/AddAds";
// import Wishes from "../../page/bussiness/wishes/Wishes";
import PhotoIcon from "@mui/icons-material/Photo";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CelebrationIcon from "@mui/icons-material/Celebration";
import "../../App.css";
import { TableButton } from "../props/Buttons";

const Sidebar = () => {
  const { sidebarHandle } = useSelector((state) => state.user);

  const location = useLocation(); // Get current location
  const dispatch = useDispatch();

  const handelSidebar = () => {
    dispatch(setSidebarHandle(false));
  };

  const [activeType, setActiveType] = useState(null);

  const handleLinkClick = (pathname) => {
    let pageName = pathname;
    pageName = pageName.split("/");
    if (pageName[1]) {
      pageName = pageName[1];
    } else {
      pageName = "/";
    }
    setActiveType(pageName);
  };

  useEffect(() => {
    handleLinkClick(location.pathname); // Use location.pathname
  }, [location.pathname]); // Depend on location.pathname

  //------------------------------pop options -----------------------------------------
  const [addImage, setAddImage] = useState(false);
  const [addAds, setAddAds] = useState(false);
  // const [wishespop, setwishespop] = useState(false);

  const handleForm = () => {
    setAddImage(!addImage);
  };
  // const handleWishespop = () => {
  //   setwishespop(!wishespop);
  // };
  const handleAdsForm = () => {
    setAddAds(!addAds);
  };

  return (
    <>
      <div>
        <div
          onClick={handelSidebar}
          className={` w-64 top-0 z-40 bg-white shadow-md fixed h-screen md:block rounded-r-primeryBRedius ${
            sidebarHandle
              ? "md:translate-x-0"
              : " -translate-x-full transition-transform duration-300 delay-300"
          } md:translate-x-0`}
        >
          {/* <div className="p-2 md:hidden text-white"><MenuIcon/></div> */}
          <div className=" text-white ">
            <div className=" absolute right-2 top-2 md:hidden ">
              <button className=" z-50 ">
                <CloseIcon />
              </button>
            </div>
            <div className=" bg-gradient-to-tl from-purple-700 to-purple-900 font-semibold py-8  rounded-r-primeryBRedius shadow mr-2 mt-2">
              <div className="flex gap-3 items-center justify-center">
                <img
                  src={OmlarLogo}
                  alt="Logo"
                  className="w-14 ring-2 ring-white rounded-full"
                />
                <h1 className="text-3xl font-bold text-white">OMLAR</h1>
              </div>
            </div>

            <Sidebartag
              name="Home"
              logo={<AppsOutlinedIcon fontSize="small" />}
              isActive={activeType === "/"}
              path={"/"}
            />
            <Sidebartag
              name="Business"
              logo={<CasesOutlinedIcon fontSize="small" />}
              isActive={activeType === "business"} // Check if current path is active
              path={PathRoute.Business}
            />
            <div onClick={handleForm}>
              <Sidebartag
                name="Background Image"
                logo={<PhotoIcon fontSize="small" />}
                isActive={addImage === true} // Check if current path is active
                path={PathRoute.Business}
              />
            </div>
            <div onClick={handleAdsForm}>
              <Sidebartag
                name="Ads"
                logo={<OndemandVideoIcon fontSize="small" />}
                isActive={addAds === true} // Check if current path is active
                path={PathRoute.Business}
              />
            </div>
            {/* <div onClick={handleWishespop}>
            <Sidebartag
              name="Wishes"
              logo={<CelebrationIcon fontSize="small" />}
              isActive={wishespop === true} // Check if current path is active
              path={PathRoute.Business}
            />
            </div> */}
            <Sidebartag
              name="Wishes"
              logo={<CelebrationIcon fontSize="small" />}
              isActive={activeType === "WishesImage"} // Check if current path is active
              path={PathRoute.WishesImage}
            />

            <Sidebartag
              name="User"
              logo={<Person4Icon fontSize="small" />}
              isActive={activeType === "users"}
              // onClick={() => setActiveTag("users")}
              path={PathRoute.Users}
            />
            <Sidebartag
              name="Share History"
              logo={<HourglassTopOutlinedIcon fontSize="small" />}
              isActive={activeType === "history"}
              // onClick={() => setActiveTag("history")}
              path={PathRoute.ShareHistory}
            />
            <Sidebartag
              name="Manage Category"
              logo={<DnsOutlinedIcon fontSize="small" />}
              isActive={activeType === "category"}
              // onClick={() => setActiveTag("category")}
              path={PathRoute.ManageCategory}
            />
            {/* <Sidebartag
              name="Manage Subcategory"
              logo={<DynamicFeedOutlinedIcon fontSize="small" />}
              isActive={activeType === "subcategory"}
              // onClick={() => setActiveTag("subcategory")}
              path={PathRoute.ManageSubCategory}
            /> */}
          </div>
          {/* <div className="w-full  flex justify-center items-end">
            <button className="py-2 px-6 bg-gray-600 text-white rounded-primeryBRedius my-6 shadow-md hover:opacity-90">
              Log out
            </button>
          </div> */}
        </div>
        {addImage && <AddBackgroundImage handleForm={handleForm} />}
        {addAds && <AddAds handleForm={handleAdsForm} />}
        {/* {wishespop && <Wishes handleForm={handleWishespop} />} */}
      </div>
    </>
  );
};

export default Sidebar;
