// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const initialValues = {
  Image: "",
};

const AddBackgroundImage = ({ handleForm }) => {
  const CreateSchema = Yup.object({
    Image: Yup.mixed().required("Image is required."),
  });

  const navigate = useNavigate();
  const { isLogged } = useSelector((state) => state.user);

  const [docs, setDocs] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [count, setCount] = useState();
  const [gelleryImg, setGellryImg] = useState([]);
  const [imageId, setImageId] = useState();
  const getImageId = (imageId) => {
    setImageId(imageId);
  };
  // console.log("imageId", imageId);

  // console.log("gelleryImg", gelleryImg);
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreateSchema,
      onSubmit: async (values, action) => {
        setLoading(true);

        let data = new FormData();
        if (docs) {
          data.append("Image", docs);
        }
        try {
          setLoading(true);
          const response = await getAxiosWithToken({
            method: "put",
            url: `Omlar_login_register/background-colours/${imageId}/update/`,
            data: data,
            reqType: "formData",
          });
          // console.log("response", response);
          if (response.data) {
            alertResponseMsgSuccess("Successful...");
            handleForm(); // Close form on successful submission
            action.resetForm();
          }
        } catch (error) {
          alertResponseMsgError("Failed to fetch event details");
          navigate("/");
        } finally {
          setLoading(false);
        }

        // if( 12 > count?.count){
        //     try {
        //         const response = await getAxiosWithToken({
        //           method: "POST",
        //           url: "Omlar_login_register/upload-background-colour/",
        //           data: data,
        //           reqType: "formData",
        //         });
        //         if (response.data) {
        //           alertResponseMsgSuccess("Successful...");
        //           handleForm(); // Close form on successful submission
        //           action.resetForm();
        //         } else {
        //           alertResponseMsgError(response.data.msg);
        //         }
        //       } catch (error) {
        //         console.error(error);
        //         if (
        //           error.response &&
        //           error.response.data &&
        //           error.response.data.msg
        //         ) {
        //           alertResponseMsgError(error.response.data.msg);
        //         } else {
        //           alertResponseMsgError("An error occurred");
        //         }
        //       } finally {
        //         setLoading(false);
        //       }

        // } else{
        //     setLoading(false);
        //     alertResponseMsgError("Add a maximum of 11 images.");
        // }
      },
    });
  //----------------------Doucments ---------------------------------------
  const [preview, setpreview] = useState();
  // const fileInputRef = useRef(null);
  //   console.log("docs", docs);

  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      // Validate file size (limit to 2 MB)
      if (file.size > 2000000) {
        alertResponseMsgError("File size should be less than 2 MB");
        return false;
      }

      // Validate file type (allow only PDF, DOCX, JPG, JPEG, PNG)
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be PDF, DOCX, JPG, JPEG, or PNG"
        );
        return false;
      }

      // If the file is valid, update the state
      setDocs(file);
      setFieldValue("Image", file); // Update Formik state

      // If you want to preview image files (optional)
      if (file.type.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(file);
        // Uncomment the line below to use for image preview
        setpreview(imageUrl);
      }
    }
  };

  // const getImage = values.Image;
  // console.log(`https://api.omlarmulti.com/media/${values.Image}`)

  //-----------------------------------------------get Image------------------------------------------
  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `Omlar_login_register/background-colours/`,
      });
      // console.log("response", response);
      if (response.data) {
        // setCount(response.data);
        setGellryImg(response.data.results);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch event details");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  // useEffect to trigger fetching event details
  useEffect(() => {
    if (isLogged) {
      getDetail();
    }
  }, [isLogged]);

  //------------------------Update bg Image---------------------------------------------

  const getDetailUpdate = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "put",
        url: `Omlar_login_register/background-colours/${imageId}/update/`,
      });
      // console.log("response", response);
      if (response.data) {
        // setCount(response.data);
        setGellryImg(response.data.results);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch event details");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  // useEffect to trigger fetching event details
  useEffect(() => {
    if (isLogged && imageId) {
      getDetailUpdate(imageId);
    }
  }, [isLogged, imageId]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center overflow-x-scroll bg-black bg-opacity-50 z-50"
      onClick={handleForm} // Close the popup when clicking the backdrop
    >
      <div
        className="absolute bg-white p-6 rounded-lg shadow-lg max-w-md w-full top-4"
        onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
      >
        <div className="bg-white">
          {loading && <div className=""></div>}

          <form onSubmit={handleSubmit} className="">
            {/* Image Upload Field */}
            <div className="mb-6">
              <label htmlFor="image" className="form-label">
                Select and Update Image 
              </label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChangeFile}
                className="form-input"
                // value={values.image || ""}
              />
              <InputError
                error={errors.Image}
                touched={touched.Image}
                warning={false}
              />
            </div>

            {/* Image Preview */}
            <div className="flex justify-center bg-gray-100 p-2">
              {preview && (
                <img
                  src={preview}
                  alt=""
                  className="w-full ring-white bg-white"
                />
              )}
            </div>
            <div className="flex justify-center bg-gray-100 p-2">
              
              {values.Image ? (
                <img
                  src={`https://api.omlarmulti.com/media/${values.Image}`}
                  alt=""
                  className="w-full ring-white bg-white"
                />
              ) : (
                <div className=" grid grid-cols-6 gap-1">
                  
                  {gelleryImg?.slice(0,11).map((image, index) => (
                    <img
                      key={index}
                      className={`w-full h-24 object-cover border-2 cursor-pointer ${imageId === image?.Id && "opacity-30"}`}
                      src={`${image?.Image}`} // Correct template literal
                      alt={`Gallery ${index + 1}`} // Dynamic alt for each image
                      onClick={() => getImageId(image?.Id)}
                    />
                  ))}
                </div> // Optional: Message when no image is set
              )}
            </div>
            {/* Submit Button */}
            <div className="flex justify-between mt-3">
              <div className="flex justify-end">
                {loading ? (
                  <AddButton type="button" name="Adding..." />
                ) : (
                  <AddButton type="submit" name="Submit" />
                )}
              </div>
              <button
                onClick={handleForm}
                type="button"
                className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBackgroundImage;
