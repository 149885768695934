import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DeleteIcon from "@mui/icons-material/Delete";
import AddVideoForm from "../../hospital/AddVideoForm";
// import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useParams } from "react-router";
import DeleteDialogBox from "../../../component/cards/DeleteDialogBox";

const VideoGridAdmin = ({ videoUrl }) => {
  const { id: OmlarId } = useParams();
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [currentVideoUrlVideo, setCurrentVideoUrlVideo] = useState("");
  const maxVideos = 9; // Limit to 9 videos
  const galleryVideos = videoUrl?.slice(0, maxVideos); // Show only the first 9 videos

  const openModalVideo = (url) => {
    const videoSrc = `${url.Video}`;
    setCurrentVideoUrlVideo(videoSrc); // Set the current video URL
    setIsModalOpenVideo(true);
  };

  const closeModalVideo = () => {
    setIsModalOpenVideo(false);
    setCurrentVideoUrlVideo(""); // Clear the current video URL when closing
  };

  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const popupVidRef = useRef(null);

  const togglePopup = (index) => {
    // Toggle popup open/close for the selected video
    if (openPopupIndex === index) {
      setOpenPopupIndex(null); // Close if the same index is clicked
    } else {
      setOpenPopupIndex(index); // Open for a new index
    }
    console.log("Selected index:", index);
  };

  const handleClickOutside = (event) => {
    if (popupVidRef.current && !popupVidRef.current.contains(event.target)) {
      setOpenPopupIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //----------------------------------------------------------------------------------
  const [imageFormShow, setImageFormShow] = useState(false);

  const [imageId, setImageId] = useState();
  const ImageForm = (Id) => {
    setImageFormShow(!imageFormShow);
    setImageId(Id);
  };

  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    // console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-upload_video/${OmlarId}/${key}/`,
        headerText: `Confirm Deletion`,
        paraText: `video`,
      });
    }
  };
  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
        {galleryVideos?.map((video, index) => (
          <div key={index} className="relative">
            <div className="absolute bottom-0 right-0 z-20">
              <div
                onClick={(e) => togglePopup(index, e)}
                className=" bg-white/50 hover:bg-white/90 p-2 rounded-full cursor-pointer"
              >
                <MoreVertIcon />
              </div>
            </div>

            <video
              key={index}
              className="w-full  h-44  object-cover cursor-pointer border-white border-4 rounded-primeryBRedius"
              onClick={() => openModalVideo(video)}
              muted
              loading="lazy"
            >
              <source src={`${video.Video}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {openPopupIndex === index && (
              <div
                ref={popupVidRef}
                className="absolute right-0 bottom-0 text-xs z-40"
              >
                <div className="py-1 flex-row text-gray-700">
                  <div
                    onClick={() => ImageForm(video?.Id)}
                    className="block px-1 py-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <EditOutlinedIcon />
                    Update
                  </div>
                  <div
                    onClick={() => handleDeleteModel(video?.Id)}
                    className="dropdownOption"
                  >
                    <DeleteIcon />
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {imageFormShow && (
          <AddVideoForm handleForm={ImageForm} videoId={imageId} />
        )}

        {isModalOpenVideo && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
            <div className="relative  rounded-lg max-w-3xl w-full">
              <div className="absolute flex gap-4 top-4 right-4"></div>
              <button
                className="absolute top-10 right-2 text-white bg-red-500 px-3 py-1 rounded-full z-30"
                onClick={closeModalVideo}
              >
                X
              </button>
              <video
                className="w-full h-screen "
                src={currentVideoUrlVideo} // Play the selected video in modal
                controls
                autoPlay
              />
            </div>
          </div>
        )}
        <DeleteDialogBox
          open={open}
          setOpen={setOpen}
          data={deleteModelData}
          // redirect="/tenant/person"
        />
      </div>
    </>
  );
};

export default VideoGridAdmin;
