import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
// import { useNavigate } from "react-router";
import InputError from "../../../component/props/InputError";

const initialValues = {
  partnerName: "",
};

const AddEmpaneledPartner = ({ handleForm }) => {
  // const usenavigate = useNavigate();

  const supplierCreateSchema = Yup.object({
    partnerName: Yup.string().required("Partner Name is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: supplierCreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("Partner_Name", values.partnerName);

      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "crud/partner/",
          data: data,
        });
        if (response.data) {
          alertResponseMsgSuccess("Successeful");
          // alertResponseMsgSuccess(response.data.msg);
          handleForm(); // Close form on successful submission
          action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("An error occurred");
        }
      }
    },
  });

  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-2xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">
              Add Empaneled Partner
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="partnerName" className="form-label">
                  Partner Name *
                </label>
                <input
                  type="text"
                  id="partnerName"
                  name="partnerName"
                  placeholder="Enter name"
                  className="popform-input"
                  value={"" || values.partnerName}
                  onChange={handleChange}
                />
                <InputError
                  error={errors.partnerName}
                  touched={touched.partnerName}
                  warning={false}
                />
              </div>

              <div onClick={handleSubmit} className="flex justify-end mt-6">
                <AddButton type="submit" name="Add" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmpaneledPartner;
