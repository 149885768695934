import React, { useEffect, useRef, useState } from "react";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithOutToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import { AddButton } from "../../../component/props/Buttons";
import InputError from "../../../component/props/InputError";
import { useParams } from "react-router";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import Fedimage from "../../../images/Fedimage.png";
import FedVideo from "../../../images/FedVideo.png";
import feedback from "../../../images/feedback.png";
import { useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import ImageView from "../../../component/props/ImageView";
import CloseIcon from "@mui/icons-material/Close";

const initialValues = {
  Business_id: "",
  Feedback: "",
  Video: "",
  Image: "",
  Mobile_No: "",
  Email_Id: "",
};

const FeedbackForm = ({ color }) => {
  const { id: OmlarId } = useParams();
  const [loading, setLoading] = useState(false);
  const { DynamicColor } = useSelector((state) => state.user);

  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  const supplierCreateSchema = Yup.object({
    Feedback: Yup.string().required("Note is required."),
    // Email_Id: Yup.string().required("Email is required."),
    Mobile_No: Yup.string().required("Mobile No. is required."),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: supplierCreateSchema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      let data = new FormData();
      data.append("Business_Id", OmlarId);
      data.append("Feedback", values.Feedback);
      data.append("Email_Id", values.Email_Id);
      data.append("Mobile_No", values.Mobile_No);
      if (video) data.append("Video", video);
      if (file) data.append("Image", file);

      try {
        const response = await getAxiosWithOutToken({
          method: "post",
          url: `crud/new-feedback/`,
          data,
          reqType: "formData",
        });
        if (response.data) {
          // alertResponseMsgSuccess(response.data.msg);
          alertResponseMsgSuccess("Successful...");

          actions.resetForm();
          setPreview(null); // Clear the preview after submission
          setVideoPreview(null);
        } else {
          alertResponseMsgError(response.data.msg);
          alertResponseMsgSuccess("Successful...");
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response?.data?.msg || "An error occurred");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
      setFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("Image").click();
  };

  const handleVideoFileChange = (e) => {
    const videoFile = e.target.files[0];
    if (videoFile) {
      const videoURL = URL.createObjectURL(videoFile);
      setVideoPreview(videoURL);
      setVideo(videoFile);
    }
  };

  const handleVideoClick = () => {
    document.getElementById("Video").click();
  };
  //---------------------------------------------------------------------------------------
  const [showInfo, setShowInfo] = useState(false);
  const InfoHandle = () => {
    setShowInfo(!showInfo);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalvid, setIsModalvid] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handlevideoModal = (e) => {
    e.stopPropagation();
    setIsModalvid(!isModalvid);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const videoRef = useRef(null);

  useEffect(() => {
    // Reload video when videoPreview changes
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoPreview]);
  return (
    <div className="my-5">
      <div
        className="flex items-center justify-center gap-2 my-2"
        style={{ color: color }}
      >
        <div className="flex items-center justify-center">
          {/* <img src={feedback} alt="feedback" className="w-14" /> */}
        </div>
        <ForumOutlinedIcon style={{ fontSize: "45px" }} />
        <h1 className="text-2xl  font-extrabold ">Feedback Corner</h1>
      </div>
      <div className="flex justify-center mb-2">
        <div
          onClick={InfoHandle}
          className="text-center text-sm flex gap-2 cursor-pointer"
        >
          <p>Click Here</p>
          <p
            className="rounded-full flex items-center px-1 text-white max-w-min"
            style={{ background: DynamicColor }}
          >
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "15px" }} />
          </p>
        </div>
      </div>
      {showInfo && (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-5 gap-2 ">
            <div
              className={`border row-span-2 col-span-3 p-2 bg-white border-gray-400`}
              // style={{ borderColor: color }}
            >
              <p className="text-sm font-bold text-gray-400">Add Note:</p>
              <InputError
                error={errors.Feedback}
                touched={touched.Feedback}
                warning={false}
              />
              <textarea
                name="Feedback"
                id="Feedback"
                rows="6"
                value={values.Feedback}
                onChange={handleChange}
                className="w-full outline-none bg-transparent"
              ></textarea>
            </div>

            <div
              className="border col-span-2 cursor-pointer bg-white border-gray-400 text-center flex items-end justify-center"
              style={{
                // borderColor: color,
                backgroundImage: `url(${Fedimage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
              }}
              onClick={handleClick}
            >
              <input
                type="file"
                name="Image"
                id="Image"
                className="hidden"
                onChange={handleFileChange}
                accept="image/*"
              />
              {preview ? (
                <div className=" relative">
                  <div
                    onClick={handleImageClick}
                    className=" absolute z-30 right-0 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <FullscreenIcon />
                  </div>
                  <img src={preview} alt="Preview" className="w-full h-fit" />
                </div>
              ) : (
                <p className="text-sm font-bold text-gray-400">Upload Image</p>
              )}
            </div>
            {isModalOpen && (
              <div
                className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
                  isModalOpen !== null ? "bg-black bg-opacity-85" : "hidden"
                }`}
              >
                <div className="absolute flex gap-4 top-4 right-4 z-20">
                  <div
                    onClick={closeModal}
                    className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                  >
                    <CloseIcon />
                  </div>
                </div>
                {isModalOpen ? (
                  <img
                    src={preview}
                    alt="Imag"
                    className="w-full shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                  />
                ) : (
                  "Loading..."
                )}
              </div>
            )}

            <div
              className="border col-span-2 cursor-pointer bg-white border-gray-400 text-center flex items-end justify-center"
              style={{
                // borderColor: color,
                backgroundImage: `url(${FedVideo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
              }}
              onClick={handleVideoClick}
            >
              <input
                type="file"
                name="Video"
                id="Video"
                className="hidden"
                onChange={handleVideoFileChange}
                accept="video/*"
              />
              {videoPreview ? (
                <div className=" relative">
                  <div
                    onClick={handlevideoModal}
                    className=" absolute z-30 right-0 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <FullscreenIcon />
                  </div>
                  <video ref={videoRef} className=" object-cover">
                    <source src={videoPreview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <p className="text-sm font-bold text-gray-400">Upload Video</p>
              )}
            </div>
            {isModalvid && (
              <div
                className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
                  isModalOpen !== null ? "bg-black bg-opacity-85" : "hidden"
                }`}
              >
                <div className="absolute flex gap-4 top-4 right-4 z-20">
                  <div
                    onClick={handlevideoModal}
                    className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                  >
                    <CloseIcon />
                  </div>
                </div>
                {isModalvid && (
                  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-85">
                    {/* Close button */}
                    <div className="absolute top-4 right-4 z-20">
                      <button
                        onClick={handlevideoModal}
                        className="text-gray-400 hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                      >
                        <CloseIcon />
                      </button>
                    </div>

                    {/* Video Preview */}
                    <div className="relative max-w-full max-h-full flex justify-center items-center">
                      {videoPreview ? (
                        <video
                          controls
                          autoPlay
                          className="w-full max-w-3xl h-auto object-cover"
                        >
                          <source src={videoPreview} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="text-white">Loading...</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className={`border flex justify-end items-center p-2 gap-2  col-span-5  bg-white border-gray-400`}
              // style={{ borderColor: color }}
            >
              <p className="text-sm font-bold text-gray-400 ">Email:</p>
              {/* <InputError
                error={errors.Email_Id}
                touched={touched.Email_Id}
                warning={false}
              /> */}
              <input
                type="email"
                value={values.Email_Id}
                onChange={handleChange}
                className="w-full outline-none bg-transparent"
                name="Email_Id"
                id="Email_Id"
              />
            </div>
            <div
              className={`border flex justify-end items-center p-2 gap-2  col-span-5  bg-white border-gray-400`}
              // style={{ borderColor: color }}
            >
              <p className="text-sm font-bold text-gray-400 w-28 ">
                Mobile No.:
              </p>
              
              <input
                type="number"
                value={values.Mobile_No}
                onChange={handleChange}
                className="w-full outline-none bg-transparent"
                name="Mobile_No"
                id="Mobile_No"
              />
              <InputError
                error={errors.Mobile_No}
                touched={touched.Mobile_No}
                warning={false}
              />
            </div>
          </div>

          <div className="flex justify-center my-2">
            {loading ? (
              <AddButton type="button" name="Loading..." />
            ) : (
              <AddButton type="submit" name="Submit" />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default FeedbackForm;
