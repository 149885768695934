import React from 'react';
// import { BackButton } from "../../component/props/Buttons";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Link } from 'react-router-dom';


const PageName = ({name,href}) => {
  return (
    <div className='flex justify-between px-3'>
       <div className="flex items-center justify-centerrounded-borderRedius">
            {/* <BackButton /> */}
            <h1 className="text-2xl text-textColor font-semibold capitalize">
              {name}
            </h1>
          </div>
          {href ? (
            <Link to={href}>
              <button
                type="button"
                className=" bg-textColor hover:opacity-90 active:mx-1 py-2 px-3 rounded-full shadow-md shadow-purple-300 text-white"
              >
                <AddOutlinedIcon/>
              </button>
            </Link>
          ) : (
            ""
          )}
    </div>
  )
}

export default PageName
