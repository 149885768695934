import { useState } from "react";
import UpdatePartner from "../../hospital/partner/UpdatePartner";

const PartnerAdmin = ({ partnerData }) => {
  //----------------------------------------------------------------------
  const [UpdateId, setUpdateId] = useState("");

  const [emplaneleUpdateShow, srtEmplanelUpdateShow] = useState(false);

  const handleEmplaneUpdateForm = (Id) => {
    srtEmplanelUpdateShow(!emplaneleUpdateShow);
    setUpdateId(Id);
  };

  console.log("partnerData",partnerData)
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4 ">
        <ul className="pl-5">
          {partnerData &&
            partnerData.map((company, index) => (
              <li
                key={index}
                className="text-xl font-semibold"
              >
                <p
                  onClick={()=>handleEmplaneUpdateForm(company)}
                  className="inline-flex items-center cursor-pointer"
                >
                  <span
                    className="pr-1 inline-block"
                    
                  >
                    |
                  </span>
                  {company.Partner_Name}
                </p>
              </li>
            ))}
        </ul>
      </div>
      {emplaneleUpdateShow && (
        <UpdatePartner
          UpdateId={UpdateId}
          handleForm={handleEmplaneUpdateForm}
        />
      )}
    </div>
  );
};

export default PartnerAdmin;
