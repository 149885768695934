import React from "react";
import { useSelector } from "react-redux";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Services = ({ handlePharmacyServicesForm, img, name, EnqNo,tag }) => {
  const { DynamicColor, UrlLink } = useSelector((state) => state.user);
  return (
    <div className=" gap-4 items-center text-center w-full my-2">
       <div
        className=" text-center rounded-xl mx-8 my-2"
        style={{ background: DynamicColor }}
      >
        <h1 className="text-xl font-extrabold text-white">{tag}</h1>
      </div>
      <div className="text-center">
        <h3
          className={`text-xl font-extrabold`}
          style={{ color: DynamicColor }}
        >
          {name}
        </h3>
        <h3 className={` font-bold`}>Enquiry No.: {EnqNo}</h3>
      </div>
      <div className="flex justify-center my-2">
        <img src={UrlLink+img} alt="img" className="w-44" />
      </div>
      {/* <div
        className=" text-center rounded-xl mx-8 my-2"
        style={{ background: DynamicColor }}
      >
        <h1 className="text-xl font-extrabold text-white">{tag}</h1>
      </div> */}

      <div className="flex justify-center">
        <div
          onClick={handlePharmacyServicesForm}
          className="text-center text-sm flex gap-2"
        >
          <p>Click Here</p>
          <p
            className=" rounded-full flex  items-center px-1 text-white  max-w-min "
            style={{ background: DynamicColor }}
          >
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "15px" }} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
