import React from "react";
// import { Link } from 'react-router-dom';
import notfound from "../../images/notfound.jpg";

const BusinessNotAvailable = () => {
  return (
    <div className="flex flex-col items-center text-center justify-center min-h-screen bg-white ">
      <img src={notfound} alt="error" className="w-96 " />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Business Not Available
      </h1>

      <p className="text-lg text-gray-600 mb-8">
        The business you are looking for is either inactive or unavailable.
      </p>
      {/* <Link 
        to="/" 
        className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
      >
        Go back to homepage
      </Link> */}
    </div>
  );
};

export default BusinessNotAvailable;
