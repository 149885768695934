import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const AllPartner = ({ partnerData }) => {
  const { DynamicColor } = useSelector((state) => state.user);
  const [showAnime, setShowAnime] = useState(false);
  function useOnScreen(callback) {
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            callback(); // Trigger the function when the element is in view
          }
        },
        { threshold: 0.1 } // Adjust threshold to define how much of the element should be in view
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) observer.unobserve(ref.current);
      };
    }, [callback]);

    return ref;
  }

  const handleOnScreen = () => {
    // console.log("Element is now in view!");
    setShowAnime(true);
    // Add any additional logic here
  };

  const ref = useOnScreen(handleOnScreen);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4 md:px-7">
        <ul ref={ref} className="pl-5">
          {partnerData &&
            partnerData.map((company, index) => (
              <li
                key={index}
                className={`text-xl font-bold transition-transform duration-700 ease-in-out animate-bounce ${
                  showAnime ? "translate-x-0 opacity-100 duration-700 delay-700 " : "-translate-x-96 opacity-0"
                }`}
              >
                <p className="inline-flex items-center">
                  <span
                    className="pr-1 inline-block"
                    style={{ color: DynamicColor }}
                  >
                    |
                  </span>
                  {company.Partner_Name}
                </p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default AllPartner;
