import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { setactiveDeactive } from "../../store/feature/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import DeleteDialogBox from "../../component/cards/DeleteDialogBox";

const initialValues = {
  Doctor_Name: "",
  Photo: null,
  Qualification: "",
  Day: "",
  Time: "",
  Doctor_Fee: "",
};

const AddDoctor = ({ handleForm, allData }) => {
  const { id: OmlarId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeDeactive } = useSelector((state) => state.user);

  const validationSchema = Yup.object({
    Doctor_Name: Yup.string().required("Doctor's name is required"),
    Photo: Yup.mixed().nullable(),
    Qualification: Yup.string().required("Qualification is required"),
    Day: Yup.string().required("Day is required"),
    Time: Yup.string().required("Time is required"),
    Doctor_Fee: Yup.number().required("Doctor Fee is required"),
  });

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const data = new FormData();
      data.append("Doctor_Name", values.Doctor_Name);
      data.append("Qualification", values.Qualification);
      data.append("Day", values.Day);
      data.append("Time", values.Time);
      data.append("Doctor_Fee", values.Doctor_Fee);
      if (values.Photo) {
        data.append("Photo", values.Photo);
      }

      try {
        const response = await getAxiosWithToken({
          method: "patch",
          url: `crud/admin-update-doctor/${OmlarId}/${allData.Id}/`,
          data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess(response.data.success);
          dispatch(setactiveDeactive(!activeDeactive));
          handleForm();
          actions.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        navigate("/");
        alertResponseMsgError(error.response?.data?.msg || "An error occurred");
      }
    },
  });

  useEffect(() => {
    if (allData) {
      setFieldValue("Doctor_Name", allData.Doctor_Name || "");
      setFieldValue("Qualification", allData.Qualification || "");
      setFieldValue("Day", allData.Day || "");
      setFieldValue("Time", allData.Time || "");
      setFieldValue("Doctor_Fee", allData.Doctor_Fee || "");
    }
  }, [allData, setFieldValue]);

  //-------------------------------------------------------------------------
 

  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
        <div className="bg-white md:p-5 p-4 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-6 text-center">Doctor</h2>

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="imageUpload" className="form-label">
                  Upload Image *
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  name="Photo"
                  accept="image/*"
                  onChange={(e) =>
                    setFieldValue("Photo", e.currentTarget.files[0])
                  }
                  className="form-input w-full border border-gray-300 p-2 rounded"
                />
                {errors.Photo && touched.Photo && (
                  <InputError error={errors.Photo} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Doctor_Name" className="form-label">
                  Doctor's Name *
                </label>
                <input
                  type="text"
                  id="Doctor_Name"
                  name="Doctor_Name"
                  placeholder="Enter doctor's name"
                  value={values.Doctor_Name}
                  onChange={(e) => setFieldValue("Doctor_Name", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Doctor_Name && touched.Doctor_Name && (
                  <InputError error={errors.Doctor_Name} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Qualification" className="form-label">
                  Qualification *
                </label>
                <input
                  type="text"
                  id="Qualification"
                  name="Qualification"
                  placeholder="Enter qualification"
                  value={values.Qualification}
                  onChange={(e) =>
                    setFieldValue("Qualification", e.target.value)
                  }
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Qualification && touched.Qualification && (
                  <InputError error={errors.Qualification} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Day" className="form-label">
                  OPD Day *
                </label>
                <input
                  type="text"
                  id="Day"
                  name="Day"
                  placeholder="Enter OPD day"
                  value={values.Day}
                  onChange={(e) => setFieldValue("Day", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Day && touched.Day && <InputError error={errors.Day} />}
              </div>

              <div className="mb-4">
                <label htmlFor="Time" className="form-label">
                  OPD Timing *
                </label>
                <input
                  type="text"
                  id="Time"
                  name="Time"
                  placeholder="Enter OPD timing"
                  value={values.Time}
                  onChange={(e) => setFieldValue("Time", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Time && touched.Time && (
                  <InputError error={errors.Time} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Doctor_Fee" className="form-label">
                  Doctor Fee *
                </label>
                <input
                  type="number"
                  id="Doctor_Fee"
                  name="Doctor_Fee"
                  placeholder="Enter doctor fee"
                  value={values.Doctor_Fee}
                  onChange={(e) => setFieldValue("Doctor_Fee", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Doctor_Fee && touched.Doctor_Fee && (
                  <InputError error={errors.Doctor_Fee} />
                )}
              </div>
            </div>

            <div
              // onClick={handleSubmit}
              className="flex gap-4 justify-end mt-6"
            >
              <AddButton type="submit" name="Update" />
              
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDoctor;
