import { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
const BusinessSection = () => {
  const sectionName = [
    "event",
    "whatsapp",
    "Gallery",
    "Video",
    "Facilities",
    "Partner",
    "Doctor",
    "Services",
    "Information",
    "Expertise",
    "blog",
    "Contact",
    "Feedbacks",
  ];

  const [showTopButton, setShowTopButton] = useState(false);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle scroll to toggle the visibility of the button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Sticky Header */}
      <div className="m-2 border-b-2 sticky top-[60px] ">
        <ul
          style={{
            listStyle: "none",
            justifyContent: "space-around",
          }}
          className="flex flex-wrap gap-1 justify-start items-start"
        >
          {sectionName.map((section, index) => (
            <li
              key={index}
              className="text-purple-600 font-semibold px-3 py-1 mt-1 hover:border-b-2 border-purple-600"
            >
              <Link to={`#${section}`} smooth>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* Go to Top Button */}
      {showTopButton && (
        <div className="fixed bottom-4 right-4 z-30">
          <button
            onClick={scrollToTop}
            className="bg-black/50 text-white rounded-full p-3 shadow-lg hover:bg-black/80"
          >
            <KeyboardDoubleArrowUpIcon/>
          </button>
        </div>
      )}
    </div>
  );
};

export default BusinessSection;
