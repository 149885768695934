import { useSelector } from "react-redux";

const AllExpertise = ({ expertise }) => {
  const { DynamicColor } = useSelector((state) => state.user);
  return (
    <div>
      <div className="my-3">
        <div className="grid grid-cols-1  gap-1 md:px-7">
          {expertise &&
            expertise.map((exprt, index) => (
              <p
                key={index}
                className=" pl-5 leading-snug text-lg  font-extrabold flex items-start gap-1"
              >
                
                <span style={{ fontSize: "15px", color:DynamicColor }}> &#9679; </span>
                {exprt.Expertise_Name}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AllExpertise;
