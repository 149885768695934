import React, { useState } from "react";
// import { DoctorFeeCard } from "../../component/cards/DoctorCard";
// import BasicDetails from "./BasicDetails";
// import DateTimeForm from "./DateTimeForm";
// import AppointmentSummary from "./AppointmentSummary";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import doctor02 from "../../images/doctor02.png";
import { useSelector } from "react-redux";
import { AddButton } from "../../component/props/Buttons";
import InputError from "../../component/props/InputError";
import QR from "../../images/QR.png";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

// import InputError from "../../../component/props/InputError";

const initialValues = {
  Doctor_Id: "",
  Date: "",
  Time: "",
  First_Name: "",
  Last_Name: "",
  Gender: "",
  Age: "",
  Phone_No: "",
  Message: "",
};

const DoctorAppointmentForm = ({
  showAppointmentform,
  drId,
  photo,
  fee,
  name,
  qualification,
  doctoropd,
  time,
}) => {
  const { UrlLink, DynamicColor } = useSelector((state) => state.user);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const CreateSchema = Yup.object({
    Date: Yup.string().required("Date is required."),
    First_Name: Yup.string().required("First Name is required."),
    Last_Name: Yup.string().required("Last Name is required."),
    Gender: Yup.string().required("Gender is required."),
    Age: Yup.number()
      .typeError("Age must be a number.")
      .min(1, "Age must be at least 1.")
      .required("Age is required."),
    Phone_No: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be numeric.")
      .min(10, "Phone number must be at least 10 digits.")
      .required("Phone number is required."),
    Message: Yup.string().required("Message is required."),
    Payment_receipt: Yup.string().required("Payment receipt is required."),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    validateForm,
    setErrors,
    setTouched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateSchema,
    onSubmit: async (values, action) => {
      let data = new FormData();
      data.append("Doctor_Id", drId);
      data.append("Date", values.Date);
      data.append("Time", time);
      data.append("First_Name", values.First_Name);
      data.append("Last_Name", values.Last_Name);
      data.append("Gender", values.Gender);
      data.append("Age", values.Age);
      data.append("Phone_No", values.Phone_No);
      data.append("Message", values.Message);
      if (file) {
        data.append("Payment_receipt", file);
      }
      try {
        const response = await getAxiosWithOutToken({
          method: "POST",
          url: "Other_crud/booking-summary/",
          data: data,
          reqType: "formData",
        });
        if (response.data) {
          alertResponseMsgSuccess("Successful...");
          showAppointmentform(); // Close form on successful submission
          action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.msg) {
          alertResponseMsgError(error.response.data.msg);
        } else {
          alertResponseMsgError("Something wents wrong");
        }
      }
    },
  });
  console.log("errors",errors)
  //-----------------------next and previus button------------------------------------
  const headers = ["Consultation", "Date & Time", "Basic Details", "Summary"];
  const [step, setStep] = useState(1);

  const nextStepDetails = () => handleNextStep(["Date"]);
  const nextStepBasic = () =>
    handleNextStep([
      "First_Name",
      "Last_Name",
      "Gender",
      "Age",
      "Phone_No",
      "Message",
    ]);

  const nextHandle = () => {
    if (drId) {
      setStep(step + 1);
    }
  };
  const handleNextStep = (fieldsToValidate) => {
    // Set touched for all fields that are being validated
    setTouched(
      fieldsToValidate.reduce((acc, field) => {
        acc[field] = true;
        return acc;
      }, {})
    );

    // Validate the form
    validateForm().then((formErrors) => {
      const hasErrors = fieldsToValidate.some((field) => formErrors[field]);

      if (!hasErrors) {
        setErrors({});
        setStep(step + 1); // Move to the next step if no errors
      } else {
        console.log("formErrors", formErrors);
        // Optionally, show a general error message or handle specific errors
      }
    });
  };

  const previousHandle = () => {
    setStep(step - 1);
  };
  const [openPaymetOp, setopenPaymetOp] = useState(false);
  const paymentPop = () => {
    setopenPaymetOp(!openPaymetOp);
  };

  //--------------------------image preview---------------------------------------------
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
      setFile(file);
      setFieldValue("Payment_receipt", file);
    }
  };
  const handleImageClick = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/30 backdrop-blur-sm">
      {/* Modal Container */}
      <div className="relative p-4  w-full my-6 mx-auto max-w-3xl">
        <div
          onClick={showAppointmentform}
          className="text-right  bg-white  cursor-pointer"
        >
          <CloseOutlinedIcon />
        </div>
        {/* Modal Content */}
        <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/* Headers Section */}
          <div className="flex justify-around  w-full mb-4">
            {headers.map((header, index) => (
              <div
                key={index}
                className={`text-sm text-center w-full font-semibold p-2 border-t-4 flex items-center  ${
                  index + 1 === step
                    ? "border-[#000000] rounded-b-xl shadow-[#000000] duration-500 "
                    : "border-gray-500"
                } shadow-md`}
              >
                {header}
              </div>
            ))}
          </div>

          {/* DoctorCard and BasicDetails Components */}
          <form onSubmit={handleSubmit}>
            <div className="px-3 py-4">
              {/* Doctort details----------------------------------------------- */}
              {step === 1 && (
                <div className=" bg-white shadow rounded-lg overflow-hidden border border-gray-200">
                  {/* Doctor Image */}
                  <div className="md:p-3 p-1">
                    <div className="  items-center justify-around px-2 ">
                      <div className=" flex items-center justify-between ">
                        <img
                          className=" lg:w-24 lg:h-24 w-24 h-24 rounded-full bg-white border"
                          src={`${photo ? UrlLink + photo : doctor02}`}
                          alt=""
                        />
                        <div className="text-right ">
                          <h2 className="text-xl text-gray-600 ">
                            {name || ""}
                          </h2>
                          <p className="text-sm font-devanagari">
                            {" "}
                            {qualification || " "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-2 justify-end my-3">
                          <h1>Fee- </h1>
                          <div
                            className={`  py-1 px-2 text-white text-center font-semibold  rounded`}
                            style={{ background: DynamicColor }}
                          >
                            {fee || ""}/-
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Appointment Button */}
                  </div>
                  <div className="flex gap-3 justify-end ">
                    <div onClick={nextHandle}>
                      <AddButton name="Next" />
                    </div>

                    {/* <button
              onClick={nextHandle}
              className="px-4 py-2 bg-[#a64686] text-white"
            >
              Next
            </button> */}
                  </div>
                </div>
              )}
              {/* Date Time  details----------------------------------------------- */}

              {step === 2 && (
                <div className="bg-white shadow rounded-lg overflow-hidden border border-gray-200">
                  <div className="  flex items-center justify-center">
                    <div className=" p-8 rounded-lg  w-full max-w-md">
                      {/* DatePicker */}
                      <div className="mb-4">
                        <label htmlFor="Date" className="form-label">
                          Select Date
                        </label>
                        <input
                          className="form-input"
                          name="Date"
                          id="Date"
                          type="date"
                          value={values.Date || ""}
                          onChange={handleChange}
                        />
                        <InputError
                          error={errors.Date}
                          touched={touched.Date}
                          warning={false}
                        />
                      </div>

                      <div className="mb-4">
                        <label className="form-label ">Time Slot</label>
                        <input
                          className="form-input "
                          type="text"
                          value={"" || time}
                          onChange={handleChange}
                          // disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 justify-end ">
                    <div onClick={previousHandle}>
                      <AddButton name="Previous" />
                    </div>
                    <div onClick={nextStepDetails}>
                      <AddButton name="Next" />
                    </div>
                  </div>
                </div>
              )}
              {/* Date Time  details----------------------------------------------- */}
              {step === 3 && (
                <div className="bg-white shadow rounded-lg overflow-hidden border border-gray-200">
                  <div className="p-3">
                    {/* Firstname */}
                    <div className="mb-4">
                      <label htmlFor="First_Name" className="form-label">
                        Firstname *
                      </label>
                      <input
                        type="text"
                        id="First_Name"
                        name="First_Name"
                        placeholder="Enter your first name"
                        value={values.First_Name || ""}
                        onChange={handleChange}
                        className="form-input"
                      />
                      <InputError
                        error={errors.First_Name}
                        touched={touched.First_Name}
                        warning={false}
                      />
                    </div>

                    {/* Lastname */}
                    <div className="mb-4">
                      <label htmlFor="Last_Name" className="form-label">
                        Lastname *
                      </label>
                      <input
                        type="text"
                        id="Last_Name"
                        name="Last_Name"
                        placeholder="Enter your last name"
                        value={values.Last_Name || ""}
                        onChange={handleChange}
                        className="form-input"
                      />
                      <InputError
                        error={errors.Last_Name}
                        touched={touched.Last_Name}
                        warning={false}
                      />
                    </div>

                    {/* Gender */}
                    <div className="mb-4">
                      <label htmlFor="Gender" className="form-label">
                        Gender *
                      </label>
                      <div className="flex flex-wrap gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="Gender"
                            value="Male"
                            checked={values.Gender === "Male"}
                            onChange={handleChange}
                            className="form-radio"
                          />
                          <span className="ml-2">Male</span>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="Gender"
                            value="Female"
                            checked={values.Gender === "Female"}
                            onChange={handleChange}
                            className="form-radio"
                          />
                          <span className="ml-2">Female</span>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="Gender"
                            value="Transgender"
                            checked={values.Gender === "Transgender"}
                            onChange={handleChange}
                            className="form-radio"
                          />
                          <span className="ml-2">Transgender</span>
                        </label>
                      </div>
                      <InputError
                        error={errors.Gender}
                        touched={touched.Gender}
                        warning={false}
                      />
                    </div>

                    {/* Age */}
                    <div className="mb-4">
                      <label htmlFor="age" className="form-label">
                        Age *
                      </label>
                      <input
                        type="number"
                        id="Age"
                        name="Age"
                        placeholder="Enter your age"
                        value={values.Age || ""}
                        onChange={handleChange}
                        className="form-input"
                      />
                      <InputError
                        error={errors.Age}
                        touched={touched.Age}
                        warning={false}
                      />
                    </div>

                    {/* Phone Number */}
                    <div className="mb-4">
                      <label htmlFor="Phone_No" className="form-label">
                        Phone Number *
                      </label>
                      <input
                        type="tel"
                        id="Phone_No"
                        name="Phone_No"
                        placeholder="Enter your number"
                        value={values.Phone_No || ""}
                        onChange={handleChange}
                        className="form-input"
                      />
                      <InputError
                        error={errors.Phone_No}
                        touched={touched.Phone_No}
                        warning={false}
                      />
                    </div>

                    {/* WhatsApp Notification */}
                    <div className="mb-4 flex items-center">
                      <input
                        type="checkbox"
                        id="sendWhatsAppNotification"
                        name="sendWhatsAppNotification"
                        checked={values.sendWhatsAppNotification}
                        onChange={handleChange}
                        className=""
                      />
                      <label
                        htmlFor="sendWhatsAppNotification"
                        className="ml-2 mt-2 form-label"
                      >
                        Send WhatsApp notification
                      </label>
                    </div>

                    {/* Note */}
                    <div className="mb-4">
                      <label htmlFor="Message" className="form-label">
                        Note
                      </label>
                      <textarea
                        id="Message"
                        name="Message"
                        placeholder="Enter your note"
                        value={values.Message || ""}
                        onChange={handleChange}
                        className="form-input"
                      />
                      <InputError
                        error={errors.Message}
                        touched={touched.Message}
                        warning={false}
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 justify-end ">
                    <div onClick={previousHandle}>
                      <AddButton name="Previous" />
                    </div>
                    <div onClick={nextStepBasic}>
                      <AddButton name="Next" />
                    </div>
                  </div>
                </div>
              )}
              {step === 4 && (
                <div className="max-w-md mx-auto text-center rounded-lg p-6 bg-white shadow  overflow-hidden border border-gray-200">
                  <h3 className="text-2xl font-bold mb-4">Summary</h3>
                  <h2 className="text-bold mb-4">
                    Your Appointment Booking Summary
                  </h2>

                  <div className="mb-4">
                    <p>Patient Name:</p>
                    <p className="text-xl font-extrabold">
                      {values.First_Name + " " + values.Last_Name || ""}
                    </p>
                  </div>

                  <div className="mb-4">
                    <p>Doctor's Name:</p>
                    <p className="text-xl font-extrabold">{name || ""}</p>
                  </div>

                  <div className="mb-4">
                    <p>Date & Time:</p>
                    <p className="text-xl font-extrabold">
                      {values.Date} <br />
                      {time}
                    </p>
                  </div>

                  <div className="mb-4">
                    <p>Total Amount Payable:</p>
                    <p className="text-xl font-extrabold">₹{fee || " "}</p>
                  </div>

                  <button
                    onClick={paymentPop}
                    type="button"
                    className="w-full  text-white py-2 rounded-md hover:bg-[#a44484]"
                    style={{ background: DynamicColor }}
                  >
                    Go to Payment
                  </button>
                </div>
              )}
            </div>
            {openPaymetOp && (
              <div className=" absolute top-0 w-full p-6 bg-white shadow  overflow-hidden border border-gray-200">
                <h3 className="text-2xl font-bold mb-4">Payment QR</h3>
                <h2 className="text-bold mb-4">scan with any UPI app</h2>
                <img src={QR} alt="QR" className="p-2" />
                <div className="mb-4">
                  <label htmlFor="Payment_receipt" className="form-label">
                    Upload Payment Screenshot *
                  </label>
                  <input
                    type="file"
                    id="Payment_receipt"
                    name="Payment_receipt"
                    onChange={handleFileChange}
                    accept="image/*"
                    className="form-input"
                  />
                  <InputError
                    error={errors.Payment_receipt}
                    touched={touched.Payment_receipt}
                    warning={false}
                  />
                </div>
                
                {preview ? (
                  <div className=" relative">
                    {/* <div
                    onClick={handleImageClick}
                    className=" absolute z-30 right-0 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <FullscreenIcon />
                  </div> */}
                    <img src={preview} alt="Preview" className="w-full h-fit" />
                  </div>
                ) : (
                  <p className="text-sm font-bold text-gray-400">
                    Upload Image
                  </p>
                )}
               
                <button
                  type="submit"
                  className="w-full  text-white py-2 my-5 rounded-md hover:bg-[#a44484]"
                  style={{ background: DynamicColor }}
                >
                  Book Appointment
                </button>
              </div>
            )}
          </form>
          {isModalOpen && (
            <div
              className={`fixed top-0 inset-0  flex items-center justify-center z-50 ${
                isModalOpen !== null ? "bg-black bg-opacity-85" : "hidden"
              }`}
            >
              <div className="absolute flex gap-4 top-4 right-4 z-20">
                <div
                  onClick={closeModal}
                  className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                >
                  <CloseIcon />
                </div>
              </div>
              {isModalOpen ? (
                <img
                  src={preview}
                  alt="Imag"
                  className="w-full shadow-lg transition-transform transform duration-300 ease-in-out scale-100  sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                />
              ) : (
                "Loading..."
              )}
            </div>
          )}

          {/* Next Button */}
          {/* {step <= 3 && (
            <div className="flex gap-3 justify-end px-6 py-4">
              <button
                onClick={previousHandle}
                className="px-4 py-2 bg-[#a64686] text-white "
              >
                Previous
              </button>
              <button
                onClick={nextHandle}
                className="px-4 py-2 bg-[#a64686] text-white"
              >
                Next
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default DoctorAppointmentForm;
