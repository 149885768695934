import React, { useEffect, useState } from "react";
// import DoctorAppointmentForm from "../../page/hospital/DoctorAppointmentForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import doctor02 from "../../images/doctor02.png";
import DoctorAppointmentTable from "../../page/bussiness/doctor/DoctorAppointmentTable";
import AddDoctor from "../../page/hospital/AddDoctor";
import { useParams } from "react-router";
import DeleteDialogBox from "./DeleteDialogBox";

export const DoctorCardAdmin = ({
  name,
  qualification,
  photo,
  fee,
  color,
  doctoropd,
  drId,
  day,
  time,
  allData,
}) => {
  const { id: OmlarId } = useParams();

  const [opdtime, setopdtime] = useState();
  const { UrlLink } = useSelector((state) => state.user);
  const [showHandle, setShowHandle] = useState(false);
  const showAppointmentform = () => {
    setShowHandle(!showHandle);
  };

  // console.log("doctorData", day,time);

  useEffect(() => {
    const result = doctoropd?.find((item) => item.Id === drId);
    setopdtime(result);
  }, [doctoropd]);

  //------------------------------------------------------------
  const [docterFromShow, srtDocterFromShow] = useState(false);

  const handledoctoerForm = () => {
    srtDocterFromShow(!docterFromShow);
  };
  //------------------------------------------------------
  //-------------------------------------------Delete API-----------------------------------------//
  const [open, setOpen] = useState(false);
  const [deleteModelData, setDeleteModelData] = useState({});

  const handleDeleteModel = (key = "none") => {
    console.log("deleteActive", key);
    if (key !== "") {
      setOpen(!open);
      setDeleteModelData({
        deleteUrl: `crud/admin-update-doctor/${OmlarId}/${allData.Id}/`,
        headerText: `Confirm Deletion`,
        paraText: `doctor?`,
      });
    }
  };

  return (
    <div className="w-full">
      {/* Doctor Image */}
      <div className=" bg-webBgColor p-4 rounded-primeryBRedius mt-2 shadow-md ">
       
        <div className=" flex items-center justify-between  ">
          <img
            className=" lg:w-24 lg:h-24 w-24 h-24 rounded-full bg-white border"
            src={`${photo ? UrlLink + photo : doctor02}`}
            alt=""
          />
          <div className="text-right ">
            <h2
              className={`text-[24px] md:text-2xl font-bold `}
              style={{ color: color }}
            >
              {name || "Doctor Name"}
            </h2>
            <p className="font-semibold"> {qualification || "Qualification"}</p>
          </div>
        </div>

        <div className="text-right ">
          <p className="">OPD Days: {day?.slice(0, 3).toUpperCase()}</p>
          <p className="">OPD Time: {time}</p>
        </div>

        {/* Appointment Button */}
        <div className="flex justify-between">
        <div className="flex gap-2 justify-end ">
          <div
            onClick={() => handledoctoerForm()}
            className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
          >
            <EditIcon/>
          </div>
          <div
             onClick={() => handleDeleteModel(drId)}
            className=" cursor-pointer px-1 py-1 my-1 bg-gray-200 rounded-full"
          >
            <DeleteIcon/>
          </div>
        </div>
          <button
            onClick={showAppointmentform}
            className={` mt-2 text-white text-center px-5 py-1 font-bold rounded-primeryBRedius `}
            style={{ background: color }}
          >
            Appointment
          </button>
        </div>

        <div>
          {docterFromShow && (
            <AddDoctor handleForm={handledoctoerForm} allData={allData} />
          )}
          {showHandle && (
            <DoctorAppointmentTable
              drId={drId}
              time={time}
              showAppointmentform={showAppointmentform}
            />
          )}
          <DeleteDialogBox
            open={open}
            setOpen={setOpen}
            data={deleteModelData}
          />
        </div>
      </div>
    </div>
  );
};
