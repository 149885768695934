import React, { useEffect, useState } from "react";
import PageName from "../../component/props/PageName";
import Dynamictable from "../../component/table/Dynamictable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { logoutUser } from "../../store/feature/userSlice";
import Wishes from "./Wishes";
import { TableButton } from "../../component/props/Buttons";
import WishCategory from "./WishCategory";

const WishesImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged, activeDeactive } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  // console.log("allDataRaw",allDataRaw)
  const getAllData = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "crud/admin/design-cards/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        await dispatch(logoutUser());
        navigate("/");
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      getAllData();
    }
  }, [isLogged, activeDeactive]);

  useEffect(() => {
    if (allDataRaw?.length > 0) {
      const modifiedData = allDataRaw
        .filter((option) => option.Active) // Filter only Active: true
        .map((option) => {
          return {
            item1: option.Business_Id,
            item2: option.Name || "NA",
            // item3: option.Year || "NA",
            // item3: option.Payment_reciept ? "Available" : "NA",
            item4: option.date || "NA",
            item5: option.phone || "NA",
            item6: option.Wish_Type || "NA",
            uniqueKey: option.Id,
            status: option.Active,
            cardDesign: option.Wish_Type || "NA",
            // uniqueKey: option.Business_Id,
            // link: PathRoute.CategoryDetailLink
          };
        });

      setTableData(modifiedData);
    }
  }, [allDataRaw, setTableData]);

  const tableHeadings = [
    "S.No",
    "Business ID",
    "Name",
    "date",
    "phone",
    "Wish Type",
    "status",
    "Details",
  ];

  //   const [showdropDown, setshowdropDown] = useState(false);
  const [wishespop, setwishespop] = useState(false);

  //   const dropDown = () => {
  //     setshowdropDown(!showdropDown);
  //   };
  const handleWishespop = () => {
    setwishespop(!wishespop);
  };
  return (
    <div>
      <div className="md:px-4">
        <div className="flex flex-wrap justify-between">
          <PageName name="Card Design" />
          <div className="relative flex items-center justify-between text-left">
            <div onClick={handleWishespop}>
              <TableButton name="Template" />
            </div>
            {wishespop && <Wishes handleForm={handleWishespop} />}
          </div>
        </div>
        <WishCategory page="Pending" />
        {/* <SearchBar /> */}
        <Dynamictable tableData={tableData} tableHead={tableHeadings} />
      </div>
    </div>
  );
};

export default WishesImage;
