import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/props/InputError";
import { setactiveDeactive } from "../../../store/feature/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";

const initialValues = {
  Shop_Name: "",
  Image: null,
  Enquiry_Number: "",
};

const EditServiceAdmin = ({ handleForm, allData,Info }) => {
  const { id: OmlarId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeDeactive } = useSelector((state) => state.user);

  const validationSchema = Yup.object({
    Shop_Name: Yup.string().required("Shop name is required"),
    Image: Yup.mixed().nullable(),
    Enquiry_Number: Yup.string().required("Enquiry number is required"),
  });

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const data = new FormData();
      
      if(Info ==="pharma"){
        data.append("Shop_Name", values.Shop_Name);
      }
      if(Info ==="diagno"){
        data.append("Diagnostic_Hospital_Name", values.Shop_Name);
      }
      data.append("Enquiry_Number", values.Enquiry_Number);
      if (values.Image) {
        data.append("Image", values.Image);
      }
      if(Info ==="pharma"){
        try {
          const response = await getAxiosWithToken({
            method: "patch",
            url: `crud/admin-update-farmacy/${OmlarId}/${allData.Id}/`,
            data,
            reqType: "formData",
          });
          if (response.data) {
            alertResponseMsgSuccess(response.data.success);
            dispatch(setactiveDeactive(!activeDeactive));
            handleForm();
            actions.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          navigate("/");
          alertResponseMsgError(error.response?.data?.msg || "An error occurred");
        }
      }
      if(Info ==="diagno"){
        try {
          const response = await getAxiosWithToken({
            method: "patch",
            url: `crud/admin-update-diagnostic/${OmlarId}/${allData.Id}/`,
            data,
            reqType: "formData",
          });
          if (response.data) {
            alertResponseMsgSuccess(response.data.success);
            dispatch(setactiveDeactive(!activeDeactive));
            handleForm();
            actions.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          navigate("/");
          alertResponseMsgError(error.response?.data?.msg || "An error occurred");
        }
      }
     
    },
  });

  useEffect(() => {
    if (allData) {
      setFieldValue("Shop_Name", allData.Shop_Name ||allData.Diagnostic_Hospital_Name || "");
      setFieldValue("Enquiry_Number", allData.Enquiry_Number || "");
    }
  }, [allData, setFieldValue]);

  return (
    <div className="popup-firstLayer">
      <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
        <div className="bg-white md:p-5 p-4 rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-6 text-center">Edit Shop Details</h2>

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="Shop_Name" className="form-label">
                  Shop Name *
                </label>
                <input
                  type="text"
                  id="Shop_Name"
                  name="Shop_Name"
                  placeholder="Enter shop name"
                  value={values.Shop_Name}
                  onChange={(e) => setFieldValue("Shop_Name", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Shop_Name && touched.Shop_Name && (
                  <InputError error={errors.Shop_Name} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="imageUpload" className="form-label">
                  Upload Image
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  name="Image"
                  accept="image/*"
                  onChange={(e) =>
                    setFieldValue("Image", e.currentTarget.files[0])
                  }
                  className="form-input w-full border border-gray-300 p-2 rounded"
                />
                {errors.Image && touched.Image && (
                  <InputError error={errors.Image} />
                )}
              </div>

              <div className="mb-4">
                <label htmlFor="Enquiry_Number" className="form-label">
                  Enquiry Number *
                </label>
                <input
                  type="text"
                  id="Enquiry_Number"
                  name="Enquiry_Number"
                  placeholder="Enter enquiry number"
                  value={values.Enquiry_Number}
                  onChange={(e) => setFieldValue("Enquiry_Number", e.target.value)}
                  className="form-input w-full border border-gray-300 p-2 rounded"
                  required
                />
                {errors.Enquiry_Number && touched.Enquiry_Number && (
                  <InputError error={errors.Enquiry_Number} />
                )}
              </div>
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditServiceAdmin;
