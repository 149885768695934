import React, { useEffect, useState } from "react";
import OmlarLogo from "../../images/OmlarLogo.png";
import banner from "../../images/banner.jpg";
// import smartphone from "../../images/smartphone.png";
// import whatsapp from "../../images/whatsapp.png";
// import gmail from "../../images/gmail.png";
// import globe from "../../images/globe.png";
// import logo from "../../images/logo.jpg";
// import doctor02 from "../../images/doctor02.png";
import pharma from "../../images/pharma.png";
// import labtest from "../../images/labtest.png";
import call from "../../images/call.png";
import email from "../../images/email.png";
import whatapps from "../../images/whatapps.png";
import website from "../../images/website.png";
// import about from "../../images/about.png";
// import address from "../../images/address.png";
import video from "../../images/video.png";
// import support from "../../images/support.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import { HospitalHeading } from "../../component/props/DetailHading";
// import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
// import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
// import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
// import CircleIcon from "@mui/icons-material/Circle";
import { DoctorCard } from "../../component/cards/DoctorCard";
import HospitalServiceCard from "../../component/cards/HospitalServiceCard";
import DoctorAtHome from "./hospitalServices/DoctorAtHome";
import PharmacyServicesForm from "./hospitalServices/PharmacyServicesForm";
import TestForm from "./hospitalServices/TestForm";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import MedicationLiquidOutlinedIcon from "@mui/icons-material/MedicationLiquidOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import MasksOutlinedIcon from "@mui/icons-material/MasksOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import CloseIcon from "@mui/icons-material/Close";
import AllPartner from "./partner/AllPartner";
import OurFacilities from "./OurFacilities/OurFacilities";
import { useDispatch } from "react-redux";
import { getAxiosWithOutToken } from "../../axios/AxiosObj";
import { setDynamicColor } from "../../store/feature/userSlice";
import { useNavigate } from "react-router";
import AllExpertise from "./expertise/AllExpertise";
import { useParams } from "react-router-dom";
import FeedbackForm from "./feedback/FeedbackForm";
import Services from "./hospitalServices/Services";
import Information from "./information/Information";
import Gallery from "./Gallery";
import VideoGrid from "./VideoGrid";
import BlogSection from "./blog/BlogSection";
import EmergencyDesk from "./EmergencyDesk";
import AdsForWeb from "../bussiness/ads/AdsForWeb";
import {
  alertResponseMsgError,
  // alertResponseMsgSuccess,
} from "../../function/utilies";

const HospitalPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();

  // console.log("params", OmlarId);
  //UrlLink- url for image
  // const { UrlLink } = useSelector((state) => state.user);
  // Set default background color to purple
  const [bgColor, setBgColor] = useState("#a64686");
  const [doctoratHomeform, setDoctoratHome] = useState(false);
  const [rehabilitationForm, setRehabilitationForm] = useState(false);
  const [pharmacyServicesForm, setPharmacyServicesForm] = useState(false);
  const [patientAttendantServicesForm, setPatientAttendantServicesForm] =
    useState(false);
  const [ICUatHomeForm, setICUatHomeForm] = useState(false);
  const [diagnosticLabTestsForm, setDiagnosticLabTestsForm] = useState(false);

  const handeDoctorForm = () => {
    setDoctoratHome(!doctoratHomeform);
  };

  const handleRehabilitationForm = () => {
    setRehabilitationForm(!rehabilitationForm);
  };

  const handlePharmacyServicesForm = () => {
    setPharmacyServicesForm(!pharmacyServicesForm);
  };

  const handlePatientAttendantServicesForm = () => {
    setPatientAttendantServicesForm(!patientAttendantServicesForm);
  };

  const handleICUatHomeForm = () => {
    setICUatHomeForm(!ICUatHomeForm);
  };

  const handleDiagnosticLabTestsForm = () => {
    setDiagnosticLabTestsForm(!diagnosticLabTestsForm);
  };

  const handleColorChange = (event) => {
    setBgColor(event.target.value); // Update the background color
  };

  //---------------------------get Business Details------------------------------------------------
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [gelleryImg, setGellryImg] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [facility, setFacility] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [doctorData, setDoctordata] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [doctoropd, setDoctoropd] = useState([]);
  const [information, setInformations] = useState([]);
  const [pharmacies, setpharmacies] = useState([]);
  const [diagnostic, setdiagnostic] = useState([]);

  const [blog, setblog] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  //---------------------------------------image Slider-------------------------------------------
  const images = [
    allDataRaw.Banner_Image &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image}`,
    allDataRaw.Banner_Image1 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image1}`,
    allDataRaw.Banner_Image2 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image2}`,
    allDataRaw.Banner_Image3 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image3}`,
    allDataRaw.Banner_Image4 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image4}`,
    allDataRaw.Banner_Image5 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image5}`,
    allDataRaw.Banner_Image6 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image6}`,
    allDataRaw.Banner_Image7 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image7}`,
    allDataRaw.Banner_Image8 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image8}`,
    allDataRaw.Banner_Image9 &&
      `https://api.omlarmulti.com/media/business/${allDataRaw.Banner_Image9}`,
  ].filter(Boolean); // filter out null or undefined images

  // Automatically change slide every 3 seconds (3000ms)
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 7000);

    // Clean up the interval on component unmount
    return () => clearInterval(slideInterval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevImage = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  //----------------------------------------Read more state -------------------------------
  const [isExpanded, setIsExpanded] = useState(false);
  // const [blogExpanded, setblogExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);
  // const blogReadMore = () => setblogExpanded(!blogExpanded);
  // const [alluserRaw, setAlluserRaw] = useState([]);

  //-----------------------------------------------------------------------
  const fetchHospitalData = async () => {
    if (!OmlarId) {
      navigate("/404");
      return;
    }
    try {
      const response = await getAxiosWithOutToken({
        method: "get",
        url: `crud/business/${OmlarId}/data/`,
      });
      if (response.data) {
        setAllDataRaw(response.data);
      } else {
        navigate("/businessnotavailable");
      }
    } catch (error) {
      alertResponseMsgError(error);
      if (error.response?.status === 401) {
        alertResponseMsgError(error);
        navigate("/businessnotavailable");
      } else {
        navigate("/businessnotavailable");
      }
    }
  };

  useEffect(() => {
    if (OmlarId) {
      fetchHospitalData();
    }
  }, [OmlarId]);

  //-----------------------------user Validation -------------------------------------------------------------------

  // const { isLogged,activeDeactive } = useSelector((state) => state.user);
  // // const [tableData, setTableData] = useState([]);
  // // const [loading, setLoading] = useState(false);
  // console.log("alluserRaw",alluserRaw)
  // const getBusinessDetail = async () => {
  //   try {
  //     // setLoading(true);
  //     const response = await getAxiosWithToken({
  //       method: "get",
  //       url: `Omlar_login_register/profile_By_Id/${OmlarId}/`,
  //     });

  //     if (response && response.data) {
  //       setAlluserRaw(response.data);
  //     } else {
  //       console.error(
  //         "Failed to fetch details:",
  //         response?.data?.msg || "No message available"
  //       );
  //     }
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       navigate("/");
  //       await dispatch(logoutUser());
  //     } else {
  //       navigate("/404");
  //     }
  //   } finally {
  //     // setLoading(false); // Stop loading
  //   }
  // };

  // useEffect(() => {
  //   if (isLogged) {
  //     getBusinessDetail();
  //   }
  // }, [isLogged,activeDeactive]);

  //-------assign value in a state-----------------------------------
  useEffect(() => {
    if (allDataRaw && allDataRaw !== " ") {
      setGellryImg(allDataRaw.galleries);
      setVideoUrl(allDataRaw?.videos);
      setFacility(allDataRaw.facilities);
      setExpertise(allDataRaw.expertise);
      setDoctordata(allDataRaw.doctors);
      setPartnerData(allDataRaw.partners);
      setDoctoropd(allDataRaw.doctor_opd);
      setInformations(allDataRaw.informations);
      setblog(allDataRaw?.our_blogs);
      setpharmacies(allDataRaw?.farmacies);
      setdiagnostic(allDataRaw?.diagnostics);
    }
  }, [allDataRaw]);
  //-------pass the dynamic color value in redux and usestate-----------------------------------

  useEffect(() => {
    if (allDataRaw && allDataRaw.Color_Scheme) {
      setBgColor(allDataRaw.Color_Scheme);
      dispatch(setDynamicColor(allDataRaw.Color_Scheme));
    } else {
      setBgColor("#a64686");
      dispatch(setDynamicColor("#a64686"));
    }
  }, [allDataRaw]);
  // Add dependency to run when allDataRaw changes

  if (!allDataRaw) {
    return <div>Loading...</div>;
  }
  const openWebsite = () => {
    let url = allDataRaw?.Website;
    // Check if the URL starts with http:// or https://
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`; // Add https:// as the default protocol
    }
    window.open(url, "_blank"); // Open the link in a new tab
  };
  //  allDataRaw?.transparency ||

  return (
    <div
      className={` object-cover `}
      style={{
        // backgroundImage: `
        //    linear-gradient(rgba(255, 255, 255, ${
        //      allDataRaw?.transparency || 0
        //    }), rgba(255, 255, 255, ${allDataRaw?.transparency || 0})),
        //   url(${allDataRaw?.background_colour})
        // `,

        backgroundSize: "cover, cover",
        backgroundPosition: "center, center",
        backgroundAttachment: "fixed",
        width: "100vw",
      }}
    >
      <header
        onChange={handleColorChange}
        className="text-white sm:py-4 sm:px-6 px-2 py-2"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex items-center justify-between ">
          <div className="flex gap-2 items-start justify-center px-2">
            <img
              src={OmlarLogo}
              alt="Logo"
              className="w-7 ring-1 ring-white rounded-full"
            />
            <div className="text-right">
              <h1 className="md:text-3xl text-2xl font-extrabold text-white">
                OMLAR
              </h1>
              <p className="text-[9px] mt-[-5px]">Stay in touch...</p>
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <h1 className=" font-bold font-poppins text-white leading-tight">
              Create New <br /> Business ID
            </h1>
          </div>
        </div>
      </header>
      {/* this is backgound image and opacity added */}
      <div className=" ">
        {" "}
        <img
          src={allDataRaw?.background_colour}
          alt=""
          className={`fixed top-0 w-full h-screen -z-10 `}
          style={{ opacity: allDataRaw?.transparency }}
        />
      </div>

      {/* banner--------------------------------------------------- */}
      <div className=" relative w-full">
        <div className="relative w-full sm:h-96 h-40 overflow-hidden">
          <div
            className={`flex  ${
              currentSlide === 0 ? "" : "transition-transform duration-700"
            }`}
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Slide ${index + 1}`}
                className="w-full sm:h-96 h-40 object-cover flex-shrink-0"
              />
            ))}
          </div>
          <div className="absolute w-full top-1/2 flex justify-between  p-2">
            <button
              type="button"
              onClick={prevImage}
              className="bg-white/70  text-black flex items-start justify-center rounded-full rotate-180"
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
            <button
              type="button"
              onClick={nextImage}
              className="bg-white/70  text-black  flex items-start justify-center rounded-full "
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </div>
        </div>
        <div className="bg-white grid grid-cols-10 ">
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
          <div className="border border-gray-500 p-[2px]"></div>
        </div>
        <div className=" flex justify-center my-4">
          <img
            src={`${
              allDataRaw.Logo
                ? `https://api.omlarmulti.com/media/business/` + allDataRaw.Logo
                : banner
            }`}
            alt="banner"
            className="sm:w-44 sm:h-44 w-24 h-24 rounded-full ring-2 ring-white bg-white"
          />
        </div>
      </div>
      {/* about section--------------------------------------------------- */}
      <div className="bg-gradient-to-t form-red-50 to-white sm:p-6 px-6    ">
        <div className="text-center">
          <h1 className={`sm:text-3xl text-[24px] font-extrabold`}>
            {allDataRaw?.Business_Name || "NA"}
          </h1>
        </div>
        {/* about us--------------------------------------------------- */}
        <div className="my-2">
          <div className="flex items-center justify-center">
            {/* <img src={about} alt="about" className="w-24 my-2" /> */}
            <div
              className="text-xl font-bold text-center"
              style={{ color: bgColor }}
            >
              <GroupsIcon style={{ fontSize: "40px" }} />
              <h1>About Us:</h1>
            </div>
          </div>
          {/* <HospitalHeading
            name="About Us:"
            color={bgColor}
            logo={<GroupsOutlinedIcon style={{ fontSize: "40px" }} />}
          /> */}
          <p className="text-sm leading-snug text-justify">
            {isExpanded
              ? allDataRaw?.About || "NA"
              : (allDataRaw?.About || "NA").slice(0, 200)}{" "}
            {/* Adjust 200 as needed */}
            {allDataRaw?.About?.length > 100 && (
              <span
                onClick={toggleReadMore}
                className=" flex justify-end my-3 cursor-pointer ml-1"
              >
                {isExpanded ? " Show Less" : " Read More..."}
              </span>
            )}
          </p>
        </div>
        {/* Chairman Message--------------------------------------------------- */}
        {/* <div className="my-2 ">
          <HospitalHeading
            name="Chairman Message:"
            color={bgColor}
            logo={<Person4OutlinedIcon style={{ fontSize: "40px" }} />}
          />
          <div className="flex mt-2">
            <div className="text-right flex flex-col items-center">
              <img
                className="sm:w-32 sm:h-32 w-20 h-24"
                src={`${
                  allDataRaw.Chairman_Photo
                    ? `https://api.omlarmulti.com/media/business/` +
                      allDataRaw.Chairman_Photo
                    : doctor02
                }`}
                alt="Chairman_Photo"
              />
              <h3 className="sm:text-xl text-sm">
                {allDataRaw?.Chairman_Name || "Chairman Name"}
              </h3>
              <p className="sm:text-lg text-right text-[9px]">
                {allDataRaw?.Chairman_Qualification || "Chairman Qualification"}
              </p>
            </div>
            <div className="w-2/3 ml-2">
              <p className="md:text text-sm leading-tight text-justify">
                {allDataRaw?.Chairman_Message || "Chairman Message"}
              </p>
            </div>
          </div>
        </div> */}
        {/* Our Vision--------------------------------------------------- */}
        {/* <div className="my-4">
          <HospitalHeading
            name="Vision & Mission:"
            color={bgColor}
            logo={<CrisisAlertOutlinedIcon style={{ fontSize: "40px" }} />}
          />
          <p className="text-sm leading-snug text-justify">
            {allDataRaw?.Vision_Mission || "Vision Mission"}
          </p>
        </div> */}
        {/*gallery--------------------------------------------------- */}
        <div className="my-2">
          <div
            className="flex gap-2 mb-4 items-center justify-center"
            style={{ col: bgColor }} // Corrected background color syntax
          >
            <div style={{ color: bgColor }}>
              <PhotoSizeSelectActualOutlinedIcon fontSize="small" />
            </div>
            <h1 className="text-lg font-bold">Our Photos</h1>
          </div>
          <Gallery gelleryImg={gelleryImg} />
        </div>
        {/*Our videos--------------------------------------------------- */}
        <div className="my-5">
          <div className="flex gap-2 mb-4  items-center justify-center">
            <div style={{ color: bgColor }}>
              <img src={video} alt="video" className="w-7 " />
            </div>{" "}
            <h1 className="text-lg font-bold ">Our Videos</h1>
          </div>
          <VideoGrid videoUrl={videoUrl} />
        </div>
        {/*Doctors Panel --------------------------------------------------- */}
        <div className="my-4">
          {/* <div className="flex items-center gap-2">
            <div
              className=" w-full border-t  p-1"
              style={{ borderBlockColor: bgColor }}
            ></div>
            <h1
              className="md:text-3xl text-[28px] w-full text-center mb-2 font-extrabold whitespace-nowrap"
              style={{ color: bgColor }}
            >
              Doctors Panel
            </h1>
            <div
              className=" w-full border-t  p-1"
              style={{ borderBlockColor: bgColor }}
            ></div>
          </div> */}

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:px-12">
            {doctorData?.map((data, index) => (
              <DoctorCard
                color={bgColor}
                key={index + "____" + data.id}
                name={data.Doctor_Name}
                drId={data.Id}
                qualification={data.Qualification}
                photo={data.Photo}
                fee={data.Doctor_Fee}
                day={data.Day}
                time={data.Time}
                doctoropd={doctoropd}
              />
            ))}
          </div>
        </div>
        {/*Our Facilities--------------------------------------------------- */}
        <div className="my-4">
          <h1
            className="text-xl mb-2 font-extrabold "
            style={{ color: bgColor }}
          >
            {/* <FiberManualRecordIcon fontSize="small" /> */}
            Our Facilities :
          </h1>
          <OurFacilities facility={facility} />
        </div>
        {/*Our Empaneled Partner --------------------------------------------------- */}
        <div className="space-y-4">
          <h1
            className="text-xl text-center mb-2 font-extrabold "
            style={{ color: bgColor }}
          >
            {/* <FiberManualRecordIcon fontSize="small" /> */}
            Our Empaneled Partner
          </h1>
          <AllPartner partnerData={partnerData} />
        </div>

        {/*Hospital Services --------------------------------------------------- */}
        <div className="my-4">
          {/* Links Section */}
          <div className="">
            {pharmacies?.map((pharma, index) => (
              <div
                key={index}
                className="flex gap-4 items-center"
              >
                <Services
                  img={pharma.Image}
                  handlePharmacyServicesForm={handlePharmacyServicesForm}
                  name={pharma.Shop_Name}
                  EnqNo={pharma.Enquiry_Number}
                  tag="PHARMACY SERVICES"
                />
              </div>
            ))}
            {pharmacyServicesForm && (
              <PharmacyServicesForm handleForm={handlePharmacyServicesForm} />
            )}
            {diagnostic?.map((diagno,index) => (
              <div
                key={index}
                className="flex gap-4 items-center"
              >
                <Services
                  img={diagno.Image}
                  handlePharmacyServicesForm={handleDiagnosticLabTestsForm}
                  name={diagno.Diagnostic_Hospital_Name}
                  EnqNo={diagno.Enquiry_Number}
                  tag="DIAGNOSTIC & LAB TESTS"
                />
              </div>
            ))}
            {diagnosticLabTestsForm && (
              <TestForm handleForm={handleDiagnosticLabTestsForm} />
            )}
            {/* <div className="my-2 items-center">
              <Services
                img={labtest}
                handlePharmacyServicesForm={handleDiagnosticLabTestsForm}
                name="Modern Diagnistic Center"
                EnqNo="8707828599"
                tag="DIAGNOSTIC & LAB TESTS"
              />
              
            </div> */}
            {/* <div className="flex gap-2 mt-5 items-center">
              <h3 className={`text-lg font-bold `} style={{ color: bgColor }}>
                <FiberManualRecordIcon fontSize="small" />
                DIAGNOSTICS/LAB TESTS
              </h3>
              <div className="flex justify-center">
                <div
                  onClick={handleDiagnosticLabTestsForm}
                  className="text-center text-sm"
                >
                  <p>Click Here</p>
                  <div className="flex justify-center">
                    <p
                      className="flex text-center rounded-full text-white max-w-min "
                      style={{ background: bgColor }}
                    >
                      <ArrowForwardIosOutlinedIcon
                        style={{ fontSize: "15px" }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              
            </div> */}
          </div>
        </div>
        <div className="my-4">
          <h1
            className="text-xl text-center text-white p-2 mx-5 rounded-xl  font-extrabold"
            style={{ backgroundColor: bgColor }}
          >
            {/* <FiberManualRecordIcon fontSize="small" /> */}
            HOME CARE SERVICES
          </h1>
          {/* Only one API is being used in each service form but the type is different as per the name */}
          <div className="grid  justify-items-center grid-cols-4 gap-2 md:px-12 my-6">
            <div>
              <HospitalServiceCard
                name="Doctor At Home"
                logo={<MedicalInformationOutlinedIcon />}
                border="top"
                handleForm={handeDoctorForm}
                color={bgColor}
              />
              {doctoratHomeform && (
                <DoctorAtHome
                  name="Doctor At Home"
                  handleForm={handeDoctorForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="Rehabilitation & Physiotherapy"
                logo={<MedicationLiquidOutlinedIcon />}
                border="bottom"
                handleForm={handleRehabilitationForm}
                color={bgColor}
              />
              {rehabilitationForm && (
                <DoctorAtHome
                  name="Rehabilitation & Physiotherapy"
                  handleForm={handleRehabilitationForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="Patient Attendant Services"
                logo={<MasksOutlinedIcon />}
                border="top"
                handleForm={handlePatientAttendantServicesForm}
                color={bgColor}
              />
              {patientAttendantServicesForm && (
                <DoctorAtHome
                  name="Patient Attendant Services"
                  handleForm={handlePatientAttendantServicesForm}
                />
              )}
            </div>
            <div>
              <HospitalServiceCard
                name="ICU at Home"
                logo={<MonitorHeartOutlinedIcon />}
                border="bottom"
                handleForm={handleICUatHomeForm}
                color={bgColor}
              />
              {ICUatHomeForm && (
                <DoctorAtHome
                  name="ICU at Home"
                  handleForm={handleICUatHomeForm}
                />
              )}
            </div>
          </div>
          {/* Information Section */}

          <div className=" md:px-12 mb-4 ">
            <Information information={information} />
          </div>
        </div>
        {/*Our Expertise --------------------------------------------------- */}
        <div className="my-2">
          <h1
            className="text-2xl text-center mb-2 font-extrabold "
            style={{ color: bgColor }}
          >
            {/* <FiberManualRecordIcon fontSize="small" /> */}
            Our Expertise
          </h1>
          <div className="md:px-12">
            <AllExpertise expertise={expertise} />
          </div>
        </div>
        {/*Our blog --------------------------------------------------- */}
        <div>
          <BlogSection blog={blog} />
        </div>
        {/* Emergency --------------------------------------------------- */}
        <div>
          <EmergencyDesk allDataRaw={allDataRaw} />
        </div>
        {/* FeedBack Corner ------------------------------------------------ */}
        <div>
          <FeedbackForm color={bgColor} />
        </div>
        <div className="space-y-4">
          {/* Address Section */}
          <div className="">
            <div
              className="flex items-start justify-center gap-2"
              style={{ color: bgColor }}
            >
              {/* <img src={address} alt="address" className="w-5" /> */}
              <PinDropIcon style={{ fontSize: "30px" }} />

              <h1
                className="text-xl mb-2 font-extrabold "
                style={{ color: bgColor }}
              >
                Address
              </h1>
            </div>

            <p className=" w-full pl-5 text-sm">
              {allDataRaw?.Address || "Address"}
            </p>
          </div>

          {/* Map Section */}
          <div className=" ">
            <div
              className="relative w-full h-40 border-4"
              style={{ borderColor: bgColor }}
            >
              <iframe
                src={allDataRaw?.Google_Map}
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.592388042031!2d82.9600448!3d25.4394621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dcd9976979b%3A0xbebb88f2a5399efe!2sAshrisha%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1695657121727!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                title="."
              ></iframe>
              {/* <div className="mt-2">
                <a
                  href="https://www.google.com/maps/dir/24.9195647,83.7948673/ashrisha+it+solutions/@25.1780436,82.7318979,9z"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Click here for directions
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="my-4">
          {/* Contact Us Section */}
          <div className="">
            <div
              className="flex justify-center items-center gap-2"
              style={{ color: bgColor }}
            >
              {/* <img src={support} alt="support" className="w-9" /> */}
              <SupportAgentIcon style={{ fontSize: "30px" }} />

              <h1 className="text-xl mb-2 font-extrabold ">
                <p>CONTACT US</p>
              </h1>
            </div>
            <div className="md:px-12 grid grid-cols-4 my-2 mx-6">
              {/* Phone Icon */}
              <div className=" font-extrabold ">
                <a
                  href={`tel:${allDataRaw?.Contact || ""}`}
                  className="text-center flex justify-center"
                >
                  <img
                    src={call}
                    alt="Phone"
                    className="w-10 h-10 cursor-pointer rounded-full"
                  />
                </a>
                <p className="my-1 text-sm text-center ">Call</p>
              </div>

              {/* WhatsApp Icon */}
              <div className="text-center font-extrabold ">
                <a
                  href={`https://wa.me/${allDataRaw?.WhatsApp_number || ""}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center flex justify-center"
                >
                  <img
                    src={whatapps}
                    alt="WhatsApp"
                    className="w-10 h-10 cursor-pointer rounded-full"
                  />
                </a>
                <p className="my-1 text-sm">WhatsApp</p>
              </div>

              {/* Email Icon */}
              <div className=" font-extrabold ">
                <a
                  href={`mailto:${allDataRaw?.Email || ""}`}
                  className="text-center flex justify-center"
                >
                  <img
                    src={email}
                    alt="Email"
                    className="w-10 h-10 cursor-pointer rounded-full"
                  />
                </a>
                <p className="my-1 text-sm text-center">E-mail</p>
              </div>

              {/* Website Icon */}
              <div className=" font-extrabold ">
                <div
                  onClick={openWebsite}
                  className="text-center flex justify-center"
                >
                  <img
                    src={website}
                    alt="Website"
                    className="w-10 h-10 cursor-pointer rounded-full"
                  />
                </div>
                <p className="my-1 text-sm text-center">Website</p>
              </div>
            </div>
          </div>
        </div>
        <AdsForWeb />

        <div
          className="text-[#fff9ff] w-full fixed bottom-0 right-0 z-40 py-2 text-center leading-tight font-extrabold overflow-hidden"
          style={{ backgroundColor: bgColor }}
        >
          <h1 className="animate-marquee inline-block whitespace-nowrap">
            अपने व्यापार को बढ़ाये, "ओमलर" ऐप पर आये
          </h1>
        </div>
        <style jsx="true">{`
          @keyframes marquee {
            0% {
              transform: translateX(100%); /* Start off-screen on the right */
            }
            50% {
              transform: translateX(0); /* Centered on the screen */
            }
            100% {
              transform: translateX(-120%); /* Exit off-screen on the left */
            }
          }
          .animate-marquee {
            display: inline-block;
            animation: marquee 10s linear infinite;
          }
        `}</style>
      </div>
    </div>
  );
};

export default HospitalPreview;
