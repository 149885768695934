import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
// import CloseIcon from "@mui/icons-material/Close";

const VideoGrid = ({ videoUrl }) => {
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [currentVideoUrlVideo, setCurrentVideoUrlVideo] = useState("");
  const maxVideos = 9; // Limit to 9 videos
  const galleryVideos = videoUrl?.slice(0, maxVideos); // Show only the first 9 videos

  const openModalVideo = (url) => {
    const videoSrc = `${url.Video}`;
    setCurrentVideoUrlVideo(videoSrc); // Set the current video URL
    setIsModalOpenVideo(true);
  };

  const closeModalVideo = () => {
    setIsModalOpenVideo(false);
    setCurrentVideoUrlVideo(""); // Clear the current video URL when closing
  };

  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const popupVidRef = useRef(null);

  const togglePopup = (index) => {
    // Toggle popup open/close for the selected video
    if (openPopupIndex === index) {
      setOpenPopupIndex(null); // Close if the same index is clicked
    } else {
      setOpenPopupIndex(index); // Open for a new index
    }
    console.log("Selected index:", index);
  };

  const handleClickOutside = (event) => {
    if (popupVidRef.current && !popupVidRef.current.contains(event.target)) {
      setOpenPopupIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const downloadVideo = async (videoUrl, fileName) => {
    try {
      // Fetch the video file
      const response = await fetch(videoUrl, { mode: "cors" });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement("a");
      link.href = blobUrl;

      // Set the file name for the download
      link.download = fileName;

      // Append the anchor to the body (not necessary but good practice)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the blob URL to release memory
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  const shareVideoFile = async (videoUrl, fileName) => {
    if (navigator.share && navigator.canShare) {
      try {
        const response = await fetch(videoUrl);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });

        if (navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: "Gallery Video",
            text: "Check out this video!",
            files: [file],
          });
          console.log("Video shared successfully");
        } else {
          alert("Sharing files is not supported on this device.");
        }
      } catch (error) {
        console.error("Error sharing the video:", error);
      }
    } else {
      alert("Direct video sharing is not supported on this browser.");
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {galleryVideos?.map((video, index) => (
          <div key={index} className="relative">
            <div className="absolute bottom-0 right-0 z-20">
              <div
                onClick={(e) => togglePopup(index, e)}
                className="text-white hover:bg-white/10 py-1 rounded-full cursor-pointer"
              >
                <MoreVertIcon />
              </div>
            </div>

            <video
              key={index}
              className="w-full border-2 h-20 rounded-2xl object-cover cursor-pointer"
              onClick={() => openModalVideo(video)}
              muted
              loading="lazy"
            >
              <source
                src={`${video.Video}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>

            {openPopupIndex === index && (
              <div
                ref={popupVidRef}
                className="absolute right-0 bottom-0 text-xs z-40"
              >
                <div className="py-1 flex-row text-gray-700">
                  {/* <div
                    onClick={() => openModalVideo(video)}
                    className="block px-1 py-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <FullscreenIcon /> Full Screen
                  </div> */}
                  <div
                    onClick={() =>
                      downloadVideo(
                        `${video.Video}`,
                        `Video_${index + 1}.mp4`
                      )
                    }
                    className="block px-1 py-1 my-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <FileDownloadIcon /> Download
                  </div>
                  <div
                    onClick={() =>
                      shareVideoFile(
                        `${video.Video}`,
                        `Video_${index + 1}.mp4`
                      )
                    }
                    className="flex items-center gap-2 px-1 py-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                  >
                    <ShareIcon fontSize="small" />
                    <p>Share</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        {isModalOpenVideo && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50">
            <div className="relative  rounded-lg max-w-3xl w-full">
              <div className="absolute flex gap-4 top-4 right-4"></div>
              <button
                className="absolute top-10 right-2 text-white bg-red-500 px-3 py-1 rounded-full z-30"
                onClick={closeModalVideo}
              >
                X
              </button>
              <video
                className="w-full h-screen sm:h-96"
                src={currentVideoUrlVideo} // Play the selected video in modal
                controls
                autoPlay
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoGrid;
