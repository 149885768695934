// import RoutePath from "../../router/PathRoute";
import {  useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../../store/feature/userSlice";

const ProfileDropDown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogout = async () => {
    navigate("/"); 
    try {
      await dispatch(userLogOut());
      // Redirect to root page after successful logout
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error as needed
    }
  };

  return (
    <div className={`absolute right-14 top-14 backdrop-blur-sm bg-white/60 border rounded-lg shadow-lg z-10 cursor-pointer`}>
      {/*white Dropdown content */}
      {/* <Link to={RoutePath.ActiveUserDetail} className="block px-4 py-2 m-1 text-textColor font-semibold text-primeryBgColor rounded-lg hover:bg-clickColor hover:text-white">
        Your Profile
      </Link> */}
      <div onClick={userLogout} className="block px-4 py-2 m-1 text-textColor rounded-lg hover:bg-clickColor hover:text-white ">
        Log out
      </div>
    </div>
  );
};

export default ProfileDropDown;
