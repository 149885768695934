import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";

const AddExpertise = ({ handleForm }) => {
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 w-full my-6 mx-auto max-w-3xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">Add Expertise</h2>

            <div className="mb-4">
              <label htmlFor="expertiseName" className="form-label">
                Expertise  *
              </label>
              <textarea
                type="text"
                id="expertiseName"
                name="expertiseName"
                placeholder="Enter expertise name"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="flex justify-end mt-6">
              <div>
                <AddButton name="Add" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExpertise;
