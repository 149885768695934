const InputError = ({ error, touched, className, warning = true }) => {  
  return (
    <>
      {/* Error message directly associated with the field */}
      <div className="absolute">
        {error && touched ? (
          <p className="text-sm text-red-600 ml-1 bg-backgroundColor">
            {error}
          </p>
        ) : null}
      </div>
      {/* <div className="absolute">
        {error || touched ? (
          <p className="text-sm text-red-600 ml-1 bg-backgroundColor">
            {error}
          </p>
        ) : null}
      </div> */}

      {/* Conditional warning message, only shown if warning is true */}
      {warning && error && touched && (
        <div className={` bg-red-200 shadow-md shadow-red-500 text-sm w-full p-2 fixed top-5 right-0`}>
          Something went wrong...
        </div>
      )}
    </>
  );
};

export default InputError;
