import React, {  useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';



const AppointmnetDetail = ({ uniqueKey, eventdeail }) => {
 
  const [open, setOpen] = useState(false);
  

 console.log("eventdeail",eventdeail)

  // console.log("EventId", values.EventId);

  //-----------------------Get Event Details by Id------------------------------------------------
  // const getDetail = async (eventdeail) => {
  //   // console.log("response");

  //   try {
  //     setLoading(true);
  //     const response = await getAxiosWithToken({
  //       method: "get",
  //       url: `crud/events/details/${eventdeail}/`,
  //     });
  //     // console.log("response", response);
  //     setUpdateData(response.data);
  //     if (response && response.data) {
  //       setValues({
  //         Title: response.data.Title,
  //         details: response.data.details,
  //         date: response.data.date,
  //         Image: response.data.Image,
  //         EventId: response.data.Id,
  //       });
  //     }
  //   } catch (error) {
  //     alertResponseMsgError("Failed to fetch event details");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // // useEffect to trigger fetching event details
  // useEffect(() => {
  //   if (isLogged && eventdeail && open) {
  //     getDetail(eventdeail);
  //   }
  // }, [isLogged, eventdeail,open]);

  // console.log("Image", values.Image);

  // Function to handle button click and open the confirmation popup
  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  // Function to handle cancellation of the action
  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  
  return (
    <>
      {/* The Activate/Deactivate Button */}
      <button
        onClick={handleButtonClick}
        className={`px-2 rounded-borderRedius py-1 text-textColor`}
      >
        <RemoveRedEyeIcon />
      </button>

      {/* Confirmation Popup */}
      {open && (
        <div
          className="fixed inset-0 flex items-center justify-center overflow-x-scroll bg-black bg-opacity-50 z-50"
          onClick={handleCancel} // Close the popup when clicking the backdrop
        >
          <div
            className="absolute bg-white p-6 rounded-lg shadow-lg max-w-md w-full top-4"
            onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
          >
            <h1 className=" form-label">Payment Screenshort</h1>
            <div className="bg-white">
              <div className="flex justify-center bg-gray-100 p-2 my-2">
                  {eventdeail ? (
                    <img
                      src={eventdeail}
                      alt=""
                      className="w-full ring-white bg-white"
                    />
                  ) : (
                    <p>No image available</p> // Optional: Message when no image is set
                  )}
                </div>

                <button
                    onClick={handleCancel}
                    type="button"
                    className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
                  >
                    Close
                  </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmnetDetail;
