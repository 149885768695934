import React, { useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { alertResponseMsgSuccess } from "../../function/utilies";
import { useDispatch, useSelector } from "react-redux";
import { setactiveDeactive } from "../../store/feature/userSlice";

const StatusButton = ({ isActive, uniqueKey }) => {
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle button click and open the confirmation popup
  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleConfirm = async (e) => {
    e.stopPropagation();
    setLoading(true);
    const data = new FormData();

    data.append("Active", !isActive ? "True" : "False");
    // console.log(data)
    try {
      const response = await getAxiosWithToken({
        method: "patch",
        url: `crud/admin/design-cards/${uniqueKey}/update-status/`,
        data: data,
        reqType: "formData",
      });

      if (response && response.data) {
        alertResponseMsgSuccess("Successfully Updated");
        dispatch(setactiveDeactive(!activeDeactive));
      }
    } catch (error) {
      console.error("Error updating status", error);
    } finally {
      setLoading(false); // Reset loading state
      setOpen(false); // Close popup
    }
  };

  // Function to handle cancellation of the action
  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };
  return (
    <>
      {/* The Activate/Deactivate Button */}
      <button
        onClick={handleButtonClick}
        className={`px-2 rounded-borderRedius py-1 text-white ${
          isActive ? "bg-red-400" : "bg-green-400"
        }`}
      >
        {isActive ? "Pendding" : "Complete"}
      </button>
      {/* Confirmation Popup */}
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-lg font-semibold mb-4">Confirm Action</h2>
            <p className="mb-6">
              Are you sure you want to mark this user as{" "}
              {isActive ? "complete" : "pending"}?
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleConfirm}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
                disabled={loading} // Disable button while loading
              >
                {loading ? "Processing..." : "Confirm"}
              </button>
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusButton;
