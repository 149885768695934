import React from "react";
import Dynamictable from "../../component/table/Dynamictable";
import SearchBar from "../../component/props/SearchBar";
import PageName from "../../component/props/PageName";

const ManageSubCategory = () => {
  const tableHeadings = [
    "S.No",
    "Subcategory Name",
    "Parent Category",
    "Description",
    "Actions",
  ];

  const tableData = [
    { sn: 1, item1: 'Cardiology', item2: 'Healthcare', item3: 'Heart-related healthcare services',action: "Action", },
    { sn: 2, item1: 'Web Development', item2: 'Technology', item3: 'Building and maintaining websites',action: "Action", },
    { sn: 3, item1: 'Primary Education', item2: 'Education', item3: 'Basic schooling and education',action: "Action", },
    { sn: 4, item1: 'Investment Banking', item2: 'Finance', item3: 'Financial advisory and banking services',action: "Action", },
    { sn: 5, item1: 'Fashion Apparel', item2: 'Retail', item3: 'Clothing and accessories',action: "Action", }
];
  return (
    <div className="md:p-4">
      <PageName name="Manage Subcategory" href={true} />
      <SearchBar />
      <Dynamictable tableData={tableData} tableHead={tableHeadings} />
    </div>
  );
};

export default ManageSubCategory;
