/* eslint-disable react/prop-types */
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import RefreshIcon from "@mui/icons-material/Refresh";

import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


export const AddButton = ({ name, type = "button" }) => {
  const { DynamicColor } = useSelector((state) => state.user);

  
  return (
    <div>
        <button
          type={type} // Set the type correctly
          className="bg-[#a64686] text-white px-4 py-2 cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs"
          style={{background: DynamicColor}}
        >
          {name}
        </button>
    </div>
  );
};

export const TableButton = ({ name, type = "button" }) => {
  
  return (
    <div>
        <button
        type={type}
        className=" bg-textColor hover:opacity-90 active:mx-1 py-2 px-3 rounded-full shadow-md shadow-purple-300 text-white"
      >
        {name}
      </button>
    </div>
  );
};



export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="flex items-center gap-4 text-xl font-semibold text-left "
        onClick={() => navigate(-1)}
      >
        <div className="hover:opacity-50 rounded-full pb-1 px-2 text-center">
          <KeyboardBackspaceOutlinedIcon />
        </div>
      </div>
    </div>
  );
};
