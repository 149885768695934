import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

// Define the dataset with months and values for Total Sales, Total Expenses, Total Profit, and Growth Rate
const dataset = [
  { month: 'January', totalSales: 2000, totalExpenses: 1500, totalProfit: 500, growthRate: 10 },
  { month: 'February', totalSales: 2500, totalExpenses: 1800, totalProfit: 700, growthRate: 12 },
  { month: 'March', totalSales: 3000, totalExpenses: 2000, totalProfit: 1000, growthRate: 15 },
  { month: 'April', totalSales: 3500, totalExpenses: 2200, totalProfit: 1300, growthRate: 18 },
  { month: 'May', totalSales: 4000, totalExpenses: 2500, totalProfit: 1500, growthRate: 20 },
  { month: 'June', totalSales: 4500, totalExpenses: 2700, totalProfit: 1800, growthRate: 22 },
  { month: 'July', totalSales: 5000, totalExpenses: 3000, totalProfit: 2000, growthRate: 25 },
  { month: 'August', totalSales: 5500, totalExpenses: 3300, totalProfit: 2200, growthRate: 28 },
  { month: 'September', totalSales: 6000, totalExpenses: 3500, totalProfit: 2500, growthRate: 30 },
  { month: 'October', totalSales: 6500, totalExpenses: 3800, totalProfit: 2700, growthRate: 32 },
  { month: 'November', totalSales: 7000, totalExpenses: 4000, totalProfit: 3000, growthRate: 35 },
  { month: 'December', totalSales: 7500, totalExpenses: 4500, totalProfit: 3000, growthRate: 40 },
];

const chartSetting = {
//   yAxis: [
//     {
//       label: 'Values',
//     },
//   ],
//   width: 1000,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const valueFormatter = (value) => `${value}`;

export default function BarsDataset() {
  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
      series={[
        { dataKey: 'totalSales', label: 'Total Sales', valueFormatter },
        { dataKey: 'totalExpenses', label: 'Total Expenses', valueFormatter },
        { dataKey: 'totalProfit', label: 'Total Profit', valueFormatter },
        { dataKey: 'growthRate', label: 'Growth Rate', valueFormatter },
      ]}
      {...chartSetting}
    />
  );
}
