import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeleteDialogBox from "../../component/cards/DeleteDialogBox";

const initialValues = {
  Image: "",
  Type: "",
};

const Wishes = ({ handleForm }) => {
  const CreateSchema = Yup.object({
    Type: Yup.string()
      .required("Please select an option"),
    Image: Yup.mixed()
      .required("File is required.")
      .test(
        "fileSize",
        "File size should be less than 2 MB",
        (value) => !value || (value && value.size <= 2000000)
      )
      .test(
        "fileType",
        "File format should be JPG, JPEG, PNG, or MP4 (for videos)",
        (value) =>
          !value ||
          ["image/jpeg", "image/jpg", "image/png", "video/mp4"].includes(
            value.type
          )
      ),
  });

  const { isLogged } = useSelector((state) => state.user);

  const [docs, setDocs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gelleryImg, setGellryImg] = useState([]);
  const [preview, setPreview] = useState();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreateSchema,
      onSubmit: async (values, action) => {
        setLoading(true);

        const data = new FormData();
        data.append("Image_Type", values.Type);
        if (docs) data.append("Image", docs);

        try {
          const response = await getAxiosWithToken({
            method: "post",
            url: "Omlar_login_register/upload-birthday-image-admin/",
            data: data,
            reqType: "formData",
          });

          if (response.data) {
            alertResponseMsgSuccess("Successful...");
            handleForm(); // Close form on success
            action.resetForm();
          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          alertResponseMsgError(
            error.response?.data?.msg || "An error occurred"
          );
        } finally {
          setLoading(false);
        }
      },
    });
  // console.log("docssssssss", docs);
  const handleChangeFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      if (file.size > 2000000) {
        alertResponseMsgError("File size should be less than 2 MB");
        return;
      }

      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
    
      ];
      if (!allowedTypes.includes(file.type)) {
        alertResponseMsgError(
          "File format should be JPG, JPEG, PNG"
        );
        return;
      }

      setDocs(file);
      setFieldValue("Image", file);

      if (file.type.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(file);
        setPreview(imageUrl);
      } else {
        setPreview(null); // Clear preview for non-image files
      }
    }
  };

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `Omlar_login_register/birthday-image/`,
      });
      if (response.data) {
        setGellryImg(response.data.results);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch event details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogged) {
      getDetail();
    }
  }, [isLogged]);


      //-------------------------------------------Delete API-----------------------------------------//
      const [open, setOpen] = useState(false);
      const [deleteModelData, setDeleteModelData] = useState({});
    
      const handleDeleteModel = (key = "none") => {
        // console.log("deleteActive", key);
        if (key !== "") {
          setOpen(!open);
          setDeleteModelData({
            deleteUrl: `Omlar_login_register/birthday-image/${key}/delete/`,
            headerText: `Confirm Deletion`,
            paraText: `image`,
          });
        }
      };


  return (
    <div
      className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-50 z-50 overflow-y-auto "
      onClick={handleForm}
    >
      <div
        className="absolute bg-white p-6 rounded-lg shadow-lg w-1/2 top-5"
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          {/* File Upload Field */}
          <div className="mb-6">
            <label htmlFor="Image" className="form-label">
              File
            </label>
            <input
              type="file"
              id="Image"
              name="Image"
              onChange={handleChangeFile}
              className="form-input"
            />
            <InputError
              error={errors.Image}
              touched={touched.Image}
              warning={false}
            />
          </div>

          {/* Type Selector */}
          <div className="mb-6">
            <label htmlFor="type" className="form-label">
              Type
            </label>
            <select
              name="Type"
              onChange={handleChange}
              value={values.Type}
              id="type"
              className="form-input"
            >
              <option value="">Select Type</option>
              <option value="Birthday">Birthday</option>
              <option value="Anniversary">Anniversary</option>
              <option value="Others">Others</option>
            </select>
            <InputError
              error={errors.Type}
              touched={touched.Type}
              warning={false}
            />
          </div>

          {/* Preview */}
          <div className="flex justify-center bg-gray-100 p-2">
            {preview ? (
              <img
                src={preview}
                alt="Preview"
                className="w-full   ring-white bg-white"
              />
            ) : (
                
                <div className=" grid grid-cols-6 gap-1">
                  
                {gelleryImg?.slice(0,11).map((image, index) => (
                  <img
                    key={index}
                    className={`w-full h-24 object-cover border-2 cursor-pointer`}
                    src={`${image?.Image_path}`} // Correct template literal
                    alt={`Gallery ${index + 1}`} // Dynamic alt for each image
                    // onClick={() => getImageId(image?.Id)}
                    onClick={() => handleDeleteModel(image?.Id)}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-between mt-3">
            {loading ? (
              <AddButton type="button" name="Adding..." />
            ) : (
              <AddButton type="submit" name="Submit" />
            )}
            <button
              onClick={handleForm}
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <DeleteDialogBox
        open={open}
        setOpen={setOpen}
        data={deleteModelData}
        // redirect="/tenant/person"
      />
    </div>
  );
};

export default Wishes;
