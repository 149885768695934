import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';

const Gallery = ({ gelleryImg }) => {
  const { UrlLink } = useSelector((state) => state.user);
  const [currentIndex, setCurrentIndex] = useState(null);

  const maxImages = 9; // Limit to 9 images
  const galleryImages = gelleryImg?.slice(0, maxImages); // Show only the first 9 images

  // Function to set the current index
  const serialNumber = (indexNum) => {
    if (indexNum >= 0 && indexNum < galleryImages.length) {
      setCurrentIndex(indexNum);
    }
  };

  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  // Display the current image based on the limited array
  const currentImage =
    galleryImages?.length > 0 ? galleryImages[currentIndex] : null;

  //------------------------------------------------------------------------------------------------
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const popupRef = useRef(null);


  const togglePopup = (index) => {
    setOpenPopupIndex(openPopupIndex === index ? null : index);
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setOpenPopupIndex(null);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the popup
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //------------------------------------------------------------------------------------.
  const downloadImage = async (imageUrl, fileName) => {
    try {
      const response = await fetch(imageUrl, { mode: 'cors' });
      const blob = await response.blob();
      
      // Create a new URL for the blob and download it
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
  
      // Programmatically click the link to trigger download
      link.click();
  
      // Revoke the blob URL to free up memory
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };
  
  //---------------------------------------------------------------------------------------
  const shareImageFile = async (imageUrl, fileName) => {
    if (navigator.share && navigator.canShare) {
      try {
        // Fetch the image as a blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
  
        // Create a File from the blob
        const file = new File([blob], fileName, { type: blob.type });
  
        // Check if the device can share files
        if (navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: 'Gallery Image',
            text: 'Check out this image!',
            files: [file] // Share the actual file
          });
          console.log('Image shared successfully');
        } else {
          alert('Sharing files is not supported on this device.');
        }
      } catch (error) {
        console.error('Error sharing the image:', error);
      }
    } else {
      alert('Direct image sharing is not supported on this browser.');
    }
  };
  

  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
      {gelleryImg?.slice(0, 9).map((image, index) => (
        <div key={index} className="relative">
          {/* More Options Button */}
          <div className="absolute bottom-0 right-0 z-20">
            <div
              onClick={() => togglePopup(index)}
              className="text-white hover:bg-white/10 py-1 rounded-full cursor-pointer"
            >
              <MoreVertIcon />
            </div>
          </div>

          {/* Image */}
          <img
            className="w-full sm:h-48 h-20 object-cover border-2 cursor-pointer"
            src={`${UrlLink}${image?.Image}`}
            alt={`Gallery ${index + 1}`}
            loading="lazy"
            onClick={() => serialNumber(index)}
          />

          {/* Popup Menu */}
          {openPopupIndex === index && (
            <div  ref={popupRef} className="absolute right-0 bottom-0 text-xs z-40 ">
              <div className="py-1 flex-row text-gray-700">
                {/* <div
                  onClick={() => serialNumber(index)}
                  className="block px-1 py-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                >
                    <FullscreenIcon/>
                  Full Screen
                </div> */}
                <div
                 onClick={() => downloadImage(`${UrlLink}${image?.Image}`, `Image_${index + 1}.jpg`)}
                //   onClick={() => alert("Download clicked")}
                  className="block px-1 py-1 my-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                >
                    <FileDownloadIcon/>
                  Download
                </div>
                <div
                 onClick={() => shareImageFile(`${UrlLink}${image?.Image}`, `Image_${index + 1}.jpg`)}
                //   onClick={() => alert("Share clicked")}
                  className=" flex items-center gap-2 px-1 py-1 font-extrabold bg-white cursor-pointer hover:bg-gray-100"
                >
                    <ShareIcon fontSize="small"/>
                 <p>Share</p> 
                </div>
              </div>
            </div>
          )}
        </div>
      ))}

        {/* -----------------Popup List------------------------------------------------------ */}
        
        {/* image zoom preview------------------------------------------------------------------ */}
        <div>
          {gelleryImg && currentIndex !== null && (
            <div
              className={`fixed top-0 inset-0 flex items-center justify-center z-50 ${
                currentIndex !== null ? "bg-black bg-opacity-85" : "hidden"
              }`}
            >
              <div className="absolute flex gap-4 top-10 right-2 z-30">
                <div
                  onClick={() => setCurrentIndex(null)}
                  className="text-white hover:bg-white/10 p-2 rounded-full cursor-pointer"
                >
                  <CloseIcon />
                </div>
              </div>
              {currentImage && currentImage.Image ? (
                <img
                  src={`${UrlLink}${currentImage.Image}`}
                  // src={`https://api.venturoxtech.com/${currentImage.file}`}
                  alt={currentImage.description || "Image"}
                  className=" shadow-lg transition-transform transform duration-300 ease-in-out scale-100 w-full sm:max-w-screen-sm md:max-w-screen-md max-h-screen lg:max-w-screen-lg"
                />
              ) : (
                "Loading..."
              )}
              <div className="absolute w-full bottom-2 flex justify-between  p-4">
                <button
                  type="button"
                  onClick={prevImage}
                  className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full rotate-180"
                >
                  <KeyboardDoubleArrowRightIcon />
                </button>
                <button
                  type="button"
                  onClick={nextImage}
                  className="bg-white/70 hover:bg-white text-black px-4 py-4 flex items-start justify-center rounded-full "
                >
                  <KeyboardDoubleArrowRightIcon />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;
