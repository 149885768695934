import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";

const EmergencyDeskForm = ({ handleForm }) => {
  return (
    <>
      <div className="popup-firstLayer">
        <div className="relative p-4 top-24 w-full my-6 mx-auto max-w-3xl">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-right">
              <button onClick={handleForm}>
                <CloseOutlinedIcon />
              </button>
            </div>
            <h2 className="text-2xl font-bold mb-2 text-center">EMERGENCY DESK</h2>
            <p className="text-center text-lg mb-6">We are available 24x7</p>

            <div className="mb-4">
              <label htmlFor="ambulanceContact" className="form-label block mb-2">
                Hospital Ambulance Contact No. *
              </label>
              <input
                type="text"
                id="ambulanceContact"
                name="ambulanceContact"
                placeholder="Enter ambulance contact no."
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="traumaCare" className="form-label block mb-2">
                Trauma & Accident Care *
              </label>
              <input
                type="text"
                id="traumaCare"
                name="traumaCare"
                placeholder="Enter trauma & accident care contact no."
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="emergencyDoctorContact" className="form-label block mb-2">
                Emergency Care Dr. Contact No. *
              </label>
              <input
                type="text"
                id="emergencyDoctorContact"
                name="emergencyDoctorContact"
                placeholder="Enter emergency care doctor contact no."
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="bloodBankContact" className="form-label block mb-2">
                Blood Bank Contact No. *
              </label>
              <input
                type="text"
                id="bloodBankContact"
                name="bloodBankContact"
                placeholder="Enter blood bank contact no."
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="address" className="form-label block mb-2">
                Address with Map *
              </label>
              <textarea
                id="address"
                name="address"
                rows="3"
                placeholder="Enter hospital address"
                className="form-input w-full border border-gray-300 p-2 rounded"
                required
              />
            </div>

            <div className="flex justify-end mt-6">
              <div>
                <AddButton name="Add" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyDeskForm;
