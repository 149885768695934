import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logoutUser } from "../../store/feature/userSlice";

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OmlarId } = useParams();
  const { isLogged } = useSelector((state) => state.user);
  const [allDataRaw, setAllDataRaw] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `Omlar_login_register/profile_By_Id/${OmlarId}/`,
      });
      if (response && response.data) {
        setAllDataRaw(response.data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        await dispatch(logoutUser());
      } else {
        navigate("/404");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    if (isLogged) {
      getDetail();
    }
  }, [isLogged]);


  return (
    <>
      <div className="bg-white h-screen">
        {loading && <div className=""></div>}
        
        <div className="  grid md:grid-cols-2 gap-5 md:p-12 p-4 ">
          <div className="mb-6 ">
            {/* Label for Name */}
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              className="form-input"
              value={allDataRaw?.Name || ""}
              disabled
            />
          </div>

          <div className="mb-6">
            {/* Label for Date of Birth */}
            <label htmlFor="dob" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              className="form-input"
              value={allDataRaw?.Dob || ""}
              disabled
            />
          </div>

          <div className="mb-6">
            {/* Label for Mobile Number */}
            <label htmlFor="mobile" className="form-label">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              placeholder="Enter mobile number"
              className="form-input"
              value={allDataRaw?.Mobile_No || ""}
              disabled
            />
          </div>

          <div className="mb-6">
            {/* Label for Business Name */}
            <label htmlFor="businessName" className="form-label">
              Business Name
            </label>
            <input
              type="text"
              id="businessName"
              name="businessName"
              placeholder="Enter business name"
              className="form-input"
              value={allDataRaw?.Business_Name || "NA"}
              disabled
            />
          </div>

          {/* <div className="mb-6">
            <label htmlFor="isActive" className="form-label">
              Is Active
            </label>
            <input
              type="checkbox"
              id="isActive"
              name="isActive"
              className="form-input"
              defaultChecked={false}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserDetail;
