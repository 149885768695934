import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { AddButton } from "../../component/props/Buttons";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/props/InputError";
import {useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setactiveDeactive } from "../../store/feature/userSlice";


const initialValues = {
  Business_Name: "",
  Logo: "",
  About: "",
  Email: "",
  Address: "",
  Website: "",
  Ambulance_Contact: "",
  Emergency_No: "",
  Blood_Bank_No: "",
  Trauma_Accidental_Care: "",
  Emergency_Care_Doctor: "",
  Telemedicine_for_Emergency_Consultations: "",
  Contact: "",
  WhatsApp_number: "",
};

const HospitalDetailsForm = ({ handleForm, businessId, updateValue, data }) => {
  const dispatch = useDispatch();
  const { activeDeactive } = useSelector((state) => state.user);
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: async (values, action) => {
        let data = new FormData();
        data.append("Business_Name", values.Business_Name);
        data.append("Logo", values.Logo);
        data.append("About", values.About);
        data.append("Email", values.Email);
        data.append("Address", values.Address);
        data.append("Website", values.Website);
        data.append("Ambulance_Contact", values.Ambulance_Contact);
        data.append("Emergency_No", values.Emergency_No);
        data.append("Blood_Bank_No", values.Blood_Bank_No);
        data.append(
          "Telemedicine_for_Emergency_Consultations",
          values.Telemedicine_for_Emergency_Consultations
        );
        data.append("Emergency_Care_Doctor", values.Emergency_Care_Doctor);
        data.append("Trauma_Accidental_Care", values.Trauma_Accidental_Care);
        data.append("WhatsApp_number", values.WhatsApp_number);
        data.append("Contact", values.Contact);

        try {
          const response = await getAxiosWithToken({
            method: "patch",
            url: `Omlar_login_register/admin-update-business/${businessId}/`,
            data: data,
            reqType: "formData",
          });
          if (response) {
            alertResponseMsgSuccess("Successful");
            handleForm();
            action.resetForm();
            dispatch(setactiveDeactive(!activeDeactive));

          } else {
            alertResponseMsgError(response.data.msg);
          }
        } catch (error) {
          console.error(error);
          // alertResponseMsgError(
          //   error.response?.data?.msg || "An error occurred"
          // );
        }
      },
    });

  const { isLogged } = useSelector((state) => state.user);
  // const [allDataRaw, setAllDataRaw] = useState([]);
  useEffect(() => {
    if (isLogged && data) {
      // setAllDataRaw(data.data);
      values.Business_Name = data?.Business_Name;
      values.About = data.About;
      values.Email = data.Email;
      values.Address = data.Address;
      values.Website = data.Website;
      values.Ambulance_Contact = data.Ambulance_Contact;
      values.Emergency_No = data.Emergency_No;
      values.Blood_Bank_No = data.Blood_Bank_No;
      values.Trauma_Accidental_Care = data.Trauma_Accidental_Care;
      values.Telemedicine_for_Emergency_Consultations =
        data.Telemedicine_for_Emergency_Consultations;
      values.Emergency_Care_Doctor = data.Emergency_Care_Doctor;
      values.WhatsApp_number = data.WhatsApp_number;
      values.Contact = data.Contact;
    }
  }, [isLogged, data]);

  return (
    <div className="popup-firstLayer ">
      <div className="relative p-4 w-full top-32 my-6 mx-auto max-w-3xl">
        <div className="bg-white p-6  rounded-lg shadow-lg">
          <div className="text-right">
            <button onClick={handleForm}>
              <CloseOutlinedIcon />
            </button>
          </div>
          <h2 className="text-2xl font-bold mb-6 text-center">
            Business Details
          </h2>

          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-2">
            <div className="mb-4">
              <label htmlFor="Business_Name" className="form-label">
                Business Name *
              </label>
              <input
                type="text"
                id="Business_Name"
                name="Business_Name"
                placeholder="Enter business name"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Business_Name}
                onChange={handleChange}
              />
              <InputError
                error={errors.Business_Name}
                touched={touched.Business_Name}
              />
            </div>

            {/* New Fields */}
            <div className="mb-4">
              <label htmlFor="Email" className="form-label">
                Email *
              </label>
              <input
                type="email"
                id="Email"
                name="Email"
                placeholder="Enter email"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Email}
                onChange={handleChange}
              />
              <InputError error={errors.Email} touched={touched.Email} />
            </div>

            <div className="mb-4">
              <label htmlFor="Address" className="form-label">
                Address *
              </label>
              <input
                type="text"
                id="Address"
                name="Address"
                placeholder="Enter address"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Address}
                onChange={handleChange}
              />
              <InputError error={errors.Address} touched={touched.Address} />
            </div>

            <div className="mb-4">
              <label htmlFor="Website" className="form-label">
                Website *
              </label>
              <input
                type="text"
                id="Website"
                name="Website"
                placeholder="Enter website URL"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Website}
                onChange={handleChange}
              />
              <InputError error={errors.Website} touched={touched.Website} />
            </div>
            <div className="mb-4">
              <label htmlFor="WhatsApp_number" className="form-label">
                WhatsApp *
              </label>
              <input
                type="text"
                id="WhatsApp_number"
                name="WhatsApp_number"
                placeholder="Enter website URL"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.WhatsApp_number}
                onChange={handleChange}
              />
              <InputError
                error={errors.WhatsApp_number}
                touched={touched.WhatsApp_number}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="Contact" className="form-label">
                Contact *
              </label>
              <input
                type="text"
                id="Contact"
                name="Contact"
                placeholder="Enter Contact "
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Contact}
                onChange={handleChange}
              />
              <InputError error={errors.Contact} touched={touched.Contact} />
            </div>

            <div className="mb-4">
              <label htmlFor="Ambulance_Contact" className="form-label">
                Ambulance Contact *
              </label>
              <input
                type="text"
                id="Ambulance_Contact"
                name="Ambulance_Contact"
                placeholder="Enter ambulance contact number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Ambulance_Contact}
                onChange={handleChange}
              />
              <InputError
                error={errors.Ambulance_Contact}
                touched={touched.Ambulance_Contact}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="Emergency_No" className="form-label">
                Emergency Number *
              </label>
              <input
                type="text"
                id="Emergency_No"
                name="Emergency_No"
                placeholder="Enter emergency contact number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Emergency_No}
                onChange={handleChange}
              />
              <InputError
                error={errors.Emergency_No}
                touched={touched.Emergency_No}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="Blood_Bank_No" className="form-label">
                Blood Bank Number *
              </label>
              <input
                type="text"
                id="Blood_Bank_No"
                name="Blood_Bank_No"
                placeholder="Enter blood bank contact number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Blood_Bank_No}
                onChange={handleChange}
              />
              <InputError
                error={errors.Blood_Bank_No}
                touched={touched.Blood_Bank_No}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="Trauma_Accidental_Care" className="form-label">
                Trauma & Accidental Care *
              </label>
              <input
                type="text"
                id="Trauma_Accidental_Care"
                name="Trauma_Accidental_Care"
                placeholder="Enter trauma care contact number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Trauma_Accidental_Care}
                onChange={handleChange}
              />
              <InputError
                error={errors.Trauma_Accidental_Care}
                touched={touched.Trauma_Accidental_Care}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="Trauma_Accidental_Care" className="form-label">
                Emergency Care Dr. Contact No *
              </label>
              <input
                type="text"
                id="Emergency_Care_Doctor"
                name="Emergency_Care_Doctor"
                placeholder="Enter Doctor number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Emergency_Care_Doctor}
                onChange={handleChange}
              />
              <InputError
                error={errors.Emergency_Care_Doctor}
                touched={touched.Emergency_Care_Doctor}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="Telemedicine_for_Emergency_Consultations"
                className="form-label"
              >
                Emergency Consultations *
              </label>
              <input
                type="text"
                id="Telemedicine_for_Emergency_Consultations"
                name="Telemedicine_for_Emergency_Consultations"
                placeholder="Enter trauma care contact number"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.Telemedicine_for_Emergency_Consultations}
                onChange={handleChange}
              />
              <InputError
                error={errors.Telemedicine_for_Emergency_Consultations}
                touched={touched.Telemedicine_for_Emergency_Consultations}
              />
            </div>
            {/* Logo */}
            <div className="mb-4">
              <label htmlFor="Logo" className="form-label">
                Logo *
              </label>
              <input
                type="file"
                id="Logo"
                name="Logo"
                className="form-input w-full border border-gray-300 p-2 rounded"
                onChange={(event) =>
                  setFieldValue("Logo", event.currentTarget.files[0])
                }
              />
              <InputError error={errors.Logo} touched={touched.Logo} />
            </div>
            {/* About Us */}
            <div className="mb-4 col-span-2">
              <label htmlFor="About" className="form-label">
                About Us *
              </label>
              <textarea
                id="About"
                name="About"
                rows="4"
                placeholder="Enter details about the business"
                className="form-input w-full border border-gray-300 p-2 rounded"
                value={values.About}
                onChange={handleChange}
              />
              <InputError error={errors.About} touched={touched.About} />
            </div>

            <div className="flex gap-4 justify-end mt-6">
              <AddButton type="submit" name="Update" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HospitalDetailsForm;
