// import building from "../../img/building.jpg";
// import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PathRoute } from "../../router/PathRoute";
const WishCategory = ({ page }) => {
  const [OverDueOn, setOverDueOn] = useState(false);
  const [RentDueOn, setRentDueOn] = useState(false);
  const [Pending, setPending] = useState(false);
  const [Complete, setComplete] = useState(false);

  useEffect(() => {
    if (page === "Pending") {
      setPending(!Pending);
    }
    if (page === "Complete") {
      setComplete(!Complete);
    }
    if (page === "OverDue") {
      setOverDueOn(!OverDueOn);
    }
    if (page === "RentDueSoon") {
      setRentDueOn(!RentDueOn);
    }
    
  }, [page]);

  return (
    <div>
      <div className="m-2 border-b-2 ">
        
        <ul className="flex flex-wrap gap-4 font-semibold text-textColor">
          <Link to={PathRoute.WishesImage} >
            <li
              className={`hover:text-black px-2 p-1 cursor-pointer hover:border-b-2 hover:border-clickColor ${
                Pending ? "border-b-2 border-clickColor" : ""
              }`}
            >
              Pending
            </li>
          </Link>
          <Link to={PathRoute.DesignComplete}>
            <li
              className={`hover:text-black px-2 p-1 cursor-pointer hover:border-b-2 hover:border-clickColor ${
                Complete ? "border-b-2 border-clickColor" : ""
              }`}
            >
              Complete
            </li>
          </Link>
          
        </ul>
      </div>
      
    </div>
  );
};

export default WishCategory;
