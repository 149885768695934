import "./App.css";
import AppRoute from "./router/AppRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  
  return (
    <>
      <ToastContainer
        style={{ fontSize: "14px", zIndex: "10000" }}
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        limit={5}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppRoute />
    </>
  );
}

export default App;
