import React, { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { alertResponseMsgError } from "../../function/utilies";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const CardDesignDetails = ({ uniqueKey }) => {
  const { isLogged } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const getDetail = async () => {
    try {
      setLoading(true);
      const response = await getAxiosWithToken({
        method: "get",
        url: `crud/admin/design-cards/${uniqueKey}/`,
      });
      if (response && response.data) {
        setUpdateData(response.data);
      }
    } catch (error) {
      alertResponseMsgError("Failed to fetch card details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogged && uniqueKey && open) {
      getDetail();
    }
  }, [isLogged, uniqueKey, open]);

  const handleButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleCancel = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleImageClick = (imageUrl, e) => {
    e.stopPropagation();
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        className="px-2 rounded py-1 text-textColor"
      >
        <EditCalendarIcon />
      </button>

      {open && (
        <div
          className="fixed inset-0 flex items-center justify-center overflow-auto top-0 bg-black bg-opacity-50 z-50"
          onClick={handleCancel}
        >
          <div
            className="absolute bg-white p-6 rounded-lg overflow-y-auto shadow-lg max-w-3xl w-full"
            onClick={(e) => e.stopPropagation()}
          >
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="bg-white space-y-4">
                <div className="grid grid-cols-2">
                  <p>
                    <strong>Business ID:</strong> {updateData?.Business_Id}
                  </p>
                  <p>
                    <strong>Name:</strong> {updateData?.Name}
                  </p>
                  <p>
                    <strong>Year:</strong> {updateData?.Year}
                  </p>
                  <p>
                    <strong>Phone:</strong> {updateData?.phone}
                  </p>
                  <p>
                    <strong>Wish Type:</strong> {updateData?.Wish_Type}
                  </p>
                  <p>
                    <strong>Date:</strong> {updateData?.date}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    {updateData?.Active ? "Pending" : "Complete"}
                  </p>
                </div>

                <div className="flex justify-evenly items-center gap-2">
                  {updateData?.card_Photo && (
                    <div>
                      <strong>Card Photo:</strong>
                      <img
                        src={updateData.card_Photo}
                        alt="Card Design"
                        className="w-64 h-64 rounded mt-2 cursor-pointer"
                        onClick={(e) =>
                          handleImageClick(updateData.card_Photo, e)
                        }
                      />
                    </div>
                  )}
                  {updateData?.Payment_reciept && (
                    <div>
                      <strong>Payment Receipt:</strong>
                      <img
                        src={updateData.Payment_reciept}
                        alt="Payment Receipt"
                        className="w-64 h-64 rounded mt-2 cursor-pointer"
                        onClick={(e) =>
                          handleImageClick(updateData.Payment_reciept, e)
                        }
                      />
                    </div>
                  )}
                </div>
                <button
                  onClick={handleCancel}
                  type="button"
                  className="px-4 py-2 my-2 bg-gray-300 text-gray-700 hover:bg-gray-400 focus:outline-none"
                >
                  Close
                </button>
              </div>
            )}
          </div>

          {selectedImage && (
            <div
              className="fixed top-0 inset-0 flex items-center justify-center z-50 bg-black bg-opacity-85"
              onClick={closeModal}
            >
              <div
                className="absolute flex gap-4 top-4 right-4 z-20"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  onClick={closeModal}
                  className="text-white hover:bg-white/10 bg-white/45 p-2 rounded-full cursor-pointer"
                >
                  <CloseIcon />
                </div>
              </div>
              <img
                src={selectedImage}
                alt="Modal Display"
                className="h-screen  shadow-lg transition-transform transform duration-300 ease-in-out scale-100"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CardDesignDetails;
