import SearchIcon from "@mui/icons-material/Search";
import user from "../../images/user.png";
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarHandle } from "../../store/feature/userSlice";
import ProfileDropDown from "../dropDown/ProfileDropDown";
import { useEffect, useRef, useState } from "react";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useNavigate } from "react-router";
const Navbar = () => {

  const { sidebarHandle,isLogged } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handelSidebar = () => {
    dispatch(setSidebarHandle(!sidebarHandle));
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const profileRef = useRef(null);
  const handleClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //------------------------get profile details-------------------------------------------------------
  const [allDataRaw, setAllDataRaw] = useState([]);
  console.log("allDataRaw",allDataRaw)
  const getBusinessDetail = async () => {
    try {
      // setLoading(true); // Start loading
      const response = await getAxiosWithToken({
        method: "get",
        url: "Omlar_login_register/profile/",
      });

      if (response && response.data) {
        setAllDataRaw(response.data);
      } else {
        console.error(
          "Failed to fetch details:",
          response?.data?.msg || "No message available"
        );
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
      } else {
      }
    }
  };

  useEffect(() => {
    if (isLogged) {
      getBusinessDetail();
    }
  }, [isLogged]);

  //--------------------------------------------------------------------------------------------
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className=" fixed top-0 right-0 w-full  z-20  ">
        <div className={`flex  items-center justify-between w-full px-3 py-2 shadow-2 md:px-6 2xl:px-11 ${showTopButton ? ("bg-white/70 backdrop-blur-md"):("")}`}>
          <div className=" flex w-full md:ml-64 items-center justify-between">
            <div className="flex gap-2 ">
              <button
                onClick={handelSidebar}
                className="md:hidden border-2 rounded-full  z-50 "
              >
                {<MenuIcon />}
              </button>
              {/* <form
                action="https://formbold.com/s/unique_form_id"
                method="POST"
              >
                <div className="flex items-center gap-4">
                  <button className="">
                    <SearchIcon />
                  </button>

                  <input
                    type="text"
                    placeholder="Type to search..."
                    className=" bg-transparent text-black focus:outline-none"
                  />
                </div>
              </form> */}
              <h1 className="text-2xl font-semibold -ml-3">Dashboard</h1>
            </div>
            <div
              onClick={handleClick}
              ref={profileRef}
              className={`flex items-center  border-l-2 md:pr-4   rounded-full ${showTopButton ? (" "):("shadow-md bg-white")}`}
            >
              <div className=" cursor-pointer bg-gray-600 rounded-full p-2 text-white  ">
                <PersonIcon/>
                {/* <img
                  src={user}
                  alt="User"
                  className="sm:w-10 sm:h-10 rounded-full"
                /> */}
              </div>
              <h1 className="text-textColor font-semibold px-2 hidden md:block">
                {allDataRaw?.Name || "Admin Panal"}
              </h1>
              {dropdownVisible && (
                <div className=" shadow-md">
                  <ProfileDropDown />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
